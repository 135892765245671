import { useContext } from 'react'
import {
  ProSidebar,
  Menu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter
} from 'react-pro-sidebar'

import 'react-pro-sidebar/dist/css/styles.css'

import { Box, IconButton, Image, Text } from '@chakra-ui/react'
import AuthContext from '../../store/auth-context'
import './Sidebar.css'
import { SidebarItem } from './SidebarItem'
import { type SidebarProps } from './Sidebar'
import { FaWindowClose } from 'react-icons/fa'

export const MobileSidebar = ({ routes, collapsed, handleCollapsedChange }: SidebarProps) => {
  const authCtx = useContext(AuthContext)

  const role = authCtx.user?.role

  return (
    <>
    { !collapsed
      ? <ProSidebar width='100vw'>
        <SidebarHeader >
          <Box display='flex' justifyContent='center' alignItems='center'>
            <Image
              src='https://firebasestorage.googleapis.com/v0/b/syndicated-loans-prototype.appspot.com/o/static%2Flogo.png?alt=media&token=6d8ca784-f084-462d-899c-867954ec4552'
              className={`sidebar-header__logo ${!collapsed ? 'opened' : ''}`}
            />
            <IconButton
              onClick={handleCollapsedChange}
              icon={<FaWindowClose />}
              aria-label='menu'
              fontSize={32}
              backgroundColor='inherit'
            />
          </Box>
          <Text textOverflow='ellipsis' whiteSpace='nowrap' align='center' mb={4}>
            Logged in as {role}
          </Text>
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape='square' onClick={handleCollapsedChange}>
            { routes.map(SidebarItem)}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          {!collapsed && (
            <Text
              p={2}
              fontSize={'sm'}
              textOverflow='ellipsis'
              whiteSpace='nowrap'
              textAlign={'center'}
            >
              © {new Date().getFullYear()} QuantumX. All rights reserved
            </Text>
          )}
        </SidebarFooter>
      </ProSidebar>
      : null

    }
    </>
  )
}
