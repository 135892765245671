import { Button, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { type Location, useLocation, useNavigate } from 'react-router-dom'
import { AmortizationTrancheTabs } from '../../../../../components/amortization/AmortizationTrancheTabs'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { type Contract } from '../../../../../types/contract'
import { ContractInformation } from './ContractInformation'
import { useState, useEffect } from 'react'
import { ContractEditDocumentation } from './ContractEditDocumentation'

interface LocationProps {
  state: {
    contract: Contract
  }
}

export const ContractDetails = () => {
  const [currentContract, setCurrentContract] = useState<Contract>()
  const location: Location = useLocation()
  const navigation = useNavigate()
  const { contract } = (location as LocationProps).state

  useEffect(() => {
    setCurrentContract(contract)
  }, [contract])

  const details = [
    { label: 'Information', component: <ContractInformation contract={contract} /> },
    {
      label: 'Amortization Schedule',
      component:
        <Stack spacing={6}>
          <MainTitle variant='subsection' title='Amortization Schedule' />
          <AmortizationTrancheTabs contract={contract} />
        </Stack>
    },
    {
      label: 'Documentation',
      component:
        <ContractEditDocumentation
          currentContract={currentContract}
          setCurrentContract={setCurrentContract}
        />
    }
  ]

  return (
    <Stack spacing={12 } mb='16'>
      <Button
        colorScheme='blackAlpha'
        width='fit-content'
        onClick={() => navigation('/auth/contracts')}
        fontSize={{ base: 14, lg: 16 }}
      >
        Back to Registered Contracts
      </Button>
      <MainTitle
        title={`Details - Contract ID #${contract.contractId}`}
        variant='subsection'
        styles={{ minWidth: '100%' }}
      />
      <Tabs>
        <TabList>
          { details.map(({ label }) =>
            <Tab
              key={label}
              fontSize={16}
              fontWeight={600}
              color='#B3B3B3'
              _selected={{ color: '#38B6FF', borderBottom: '2px solid #38B6FF' }}
              _hover={{ color: '#83D0FD', borderBottom: '2px solid #83D0FD' }}
            >{label}</Tab>
          )}
        </TabList>
        <TabPanels>
          { details.map(({ component }, index) => <TabPanel mt={12} key={index}>{component}</TabPanel>) }
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
