import httpService from './httpService'

const usersEndpoint = '/users.json'

export function addUser (user) {
  return httpService.post(usersEndpoint, user, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export async function getAllUsers () {
  const response = await httpService.get(usersEndpoint)
  return response.data
}

export function getUserByEmail (email) {
  return httpService.get(`${usersEndpoint}?orderBy="email"&equalTo="${email}"&print=pretty`)
}

export function updateUser (id, updatedFields) {
  const updateEndpoint = `users/${id}.json`

  return httpService.patch(updateEndpoint, updatedFields, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}
