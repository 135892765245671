import { MyLoanDocuments } from '../../../../../components/documents/MyLoanDocuments'
import { useContract } from '../../../../../hooks/useContract'
import { type Contract } from '../../../../../types/contract'
import { type document, type FOLDERS } from '../../../../../types/documents'

interface Props {
  currentContract: Contract | undefined
  setCurrentContract: React.Dispatch<React.SetStateAction<Contract | undefined>>
}

export const ContractEditDocumentation = ({ currentContract, setCurrentContract }: Props) => {
  const { updateContractValues } = useContract()

  if (!currentContract) return null

  const onLoadFile = async (file: document, folder: FOLDERS) => {
    let newDocuments = { ...DEFAULT_DOCUMENTS, ...currentContract?.documents }
    setCurrentContract(prev => {
      if (!prev) return prev
      const preventUndefinedFolders = { ...DEFAULT_DOCUMENTS, ...prev.documents }
      newDocuments = { ...preventUndefinedFolders, [folder]: [...preventUndefinedFolders[folder], file] }
      return { ...prev, documents: newDocuments }
    })
    await updateContractValues(currentContract?._id ?? '', { documents: newDocuments })
  }

  const onDeleteFile = async (file: document, folder: FOLDERS) => {
    let newDocuments = currentContract.documents
    setCurrentContract(prev => {
      if (!prev) return prev
      newDocuments = {
        ...prev.documents,
        [folder]: prev.documents[folder].filter((document) => document._id !== file._id)
      }
      return {
        ...currentContract,
        documents: newDocuments
      }
    })
    await updateContractValues(currentContract?._id ?? '', { documents: newDocuments })
  }

  return (
    <MyLoanDocuments
      files={currentContract.documents}
      onLoadFile={onLoadFile}
      onDelete={onDeleteFile}
    />
  )
}

const DEFAULT_DOCUMENTS = {
  BUSINESS: [],
  FINANCIAL: [],
  LEGAL: [],
  OTHERS: []
}
