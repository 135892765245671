import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid } from '@chakra-ui/react'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type IFinancingStructure } from '../../../../../types/contract'
import { Item } from './Item'

interface Props {
  financingStructures: IFinancingStructure[]
}

export const Tranches = ({ financingStructures }: Props) => {
  const FS_OPTIONS = financingStructures.map(fs => {
    return [
      { left: 'TRANCHE', right: fs.tranche },
      { left: 'FINANCING TYPE', right: fs.financingType },
      { left: 'CURRENCY', right: fs.currency },
      { left: 'TERM (YEARS)', right: formatNumber(fs.term, fs.currency as ICURRENCY) },
      { left: 'AMOUNT', right: formatNumber(fs.amount, fs.currency as ICURRENCY, true) }
    ]
  })
  return (
    <AccordionItem >
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Tranches
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          { FS_OPTIONS.map((bank, index) =>
            <Item
              key={`tranche-${index}`}
              info={bank}
              title={`5. Tranches ${index + 1}`}
            />
          )}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
