import { Icon, IconButton } from '@chakra-ui/react'
import { memo } from 'react'

interface Props {
  icon: any
  'aria-label': string
  onClick: () => void
  styles?: any
  [propName: string]: any
}

const MyIconButton = ({ icon, styles = null, ...rest }: Props) => {
  const containerStyles = {
    width: '100%',
    textAlign: 'center',
    ...styles
  }
  return (
    <div style={containerStyles}>
      <IconButton icon={<Icon as={icon} boxSize={6}/>} {...rest} />
    </div>
  )
}

export default memo(MyIconButton)
