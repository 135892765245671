import { Box, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../../components/utils/currency'
import { type IGuarantees } from '../../../../../../types/contract'

interface Props {
  guarantee: IGuarantees
}

export const GuaranteeItem = ({ guarantee }: Props) => {
  const BOX_ONE = [
    { label: 'Client Name', value: guarantee.clientName },
    { label: 'NOTARY', value: guarantee.notary },
    { label: 'GUARANTEE CONTROL CARD NUMBER', value: formatNumber(guarantee.controlCard, guarantee.currency as ICURRENCY) },
    { label: 'REPERTOIRE', value: guarantee.repertoire },
    { label: 'COMMERCIAL VALUE', value: formatNumber(guarantee.commercialValue, guarantee.currency as ICURRENCY) },
    { label: 'GUARANTEE TYPE', value: guarantee.type },
    { label: 'CURRENCY', value: guarantee.currency },
    { label: 'LAWYER IN CHARGE OF GUARANTEE CONTROL', value: guarantee.lawyer },
    { label: 'APPRAISAL', value: formatNumber(guarantee.appraisal, guarantee.currency as ICURRENCY) },
    { label: 'GUARANTEE LIQUIDATION VALUE', value: formatNumber(guarantee.liquidationValue, guarantee.currency as ICURRENCY) },
    { label: 'GUARANTEE CONSTITUTION DATE', value: format(new Date(guarantee.constitutionDate), 'dd/MM/yyy') },
    { label: 'GUARANTEE EXPIRATION DATE', value: format(new Date(guarantee.expirationDate), 'dd/MM/yyyy') }
  ]
  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='60%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        <MainTitle title='7. Guarantee' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px' gap='8px' >
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748' width='60%'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='40%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        bg='#E2E8F0'
        borderRadius={12}
        width='40%'
        p='26px'
        h='fit-content'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        <MainTitle title='7. Notes' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
        <Text>{guarantee.note}</Text>
      </Box>
    </Box>
  )
}
