import { Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import MyIconButton from '../../../../components/MyIconButton/MyIconButton'
import { MyTable } from '../../../../components/table/MyTable'
import { formatNumber, type ICURRENCY } from '../../../../components/utils/currency'
import { useDialog } from '../../../../hooks/useDialog'
import { type Contract } from '../../../../types/contract'
import { ValidateContract } from './ValidateContract'

interface Props {
  contracts: Contract[]
  onValidateContract: (contractId: string, isValid: boolean, rejectedNote: string) => Promise<void>
}

interface RowsProps extends Contract {
  detailsButton: JSX.Element
  totalAmountParsed: string
  actionButton: JSX.Element
}

export const PendingContracts = ({ contracts, onValidateContract }: Props) => {
  const [rows, setRows] = useState<RowsProps[]>([])
  const { closeDialog, data, isOpen, openDialog } = useDialog<Contract>()
  const navigate = useNavigate()

  useEffect(() => {
    setRows(contracts.map(contract => {
      return {
        ...contract,
        totalAmountParsed: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true),
        detailsButton:
          <MyIconButton
            aria-label='View details'
            boxSize={8}
            icon={MdAdd}
            colorScheme='teal'
            variant='outline'
            onClick={() => navigate(`/auth/contracts/${contract.contractId}`, { state: { contract } })}
          />,
        actionButton:
          <Text
            color='#38B6FF'
            cursor='pointer'
            _hover={{ textDecoration: 'underline' }}
            fontSize='14px'
            fontWeight={500}
            onClick={() => {
              if (isOpen) closeDialog()
              else openDialog(contract)
            }}
          >
            Validate
          </Text>
      }
    }))
  }, [contracts, isOpen])

  return (
    <>
      <ValidateContract
        isOpen={isOpen}
        onClose={closeDialog}
        contract={data}
        onValidateContract={onValidateContract}
      />
      <MyTable
        rows={rows}
        headers={headers}
      />
    </>
  )
}

const headers = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Total amount', value: 'totalAmountParsed' },
  { label: 'Currency', value: 'currency' },
  { label: 'VIEW DETAILS', value: 'detailsButton' },
  { label: 'Action', value: 'actionButton' }
]
