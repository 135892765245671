import Pagination from '@mui/material/Pagination'
import { useEffect, useState } from 'react'
import { scrollToTop } from '../components/utils/utils'

const QUANTITY = 15

export const usePagination = (rowData: any[]) => {
  const [filterData, setFilterData] = useState(rowData)
  const [actualPage, setActualPage] = useState(1)
  const [pagesQuantity, setPagesQuantity] = useState(0)

  useEffect(() => {
    setFilterData(rowData.filter((e, index) =>
      (index + 1) <= QUANTITY
    ))
    setPagesQuantity(Math.ceil(rowData.length / QUANTITY))
  }, [rowData])

  useEffect(() => {
    scrollToTop()
    const constrainFilter = actualPage * QUANTITY
    setFilterData(rowData.filter((e, index) =>
      index >= (constrainFilter - QUANTITY) && index < constrainFilter
    ))
  }, [actualPage])

  const onChangePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setActualPage(page)
  }

  const MyPagination = () => (
      <>
      { rowData.length > QUANTITY
        ? <div style={{ display: 'flex', justifyContent: 'right' }}>
          <Pagination count={pagesQuantity} onChange={onChangePage} page={actualPage} />
        </div>
        : null
      }
      </>
  )

  return { MyPagination, filterData }
}
