import { Box, Button, Stack } from '@chakra-ui/react'
import { MyLoanDocuments } from '../../../../../../components/documents/MyLoanDocuments'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { type document, type FOLDERS } from '../../../../../../types/documents'

export const Documents = () => {
  const { contract, setContract, nextStep, prevStep } = useCreateContractContext()

  const onLoadFile = (file: document, folder: FOLDERS) => {
    setContract(pev => {
      return {
        ...pev,
        documents: {
          ...pev.documents,
          [folder]: [...pev.documents[folder], file]
        }
      }
    })
  }

  const onDelete = (file: document, folder: FOLDERS) => {
    setContract({
      ...contract,
      documents: {
        ...contract.documents,
        [folder]: contract.documents[folder].filter((document) => document._id !== file._id)
      }
    })
  }

  return (
    <Stack spacing={8}>
      <MainTitle title='12. Documentation' variant='subsection' styles={{ maxWidth: '100%' }} />
      <MyLoanDocuments
        files={contract.documents}
        onLoadFile={onLoadFile}
        onDelete={onDelete}
      />
      <Box width='100%' textAlign='right' mt='8px'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button onClick={nextStep} colorScheme='teal' ml='12px' fontSize={{ base: 14, lg: 16 }}>
          Next step
        </Button>
      </Box>
    </Stack>
  )
}
