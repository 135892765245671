import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import * as yup from 'yup'
import FormikControl from '../../../../../../components/formComponents/FormikControl'
import { CURRENCY_OPTIONS } from '../../../../../../components/utils/currency'
import { type IInsurance } from '../../../../../../types/contract'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (insurance: IInsurance) => void
}

export const InsuranceModal = ({ isOpen, onClose, onAdd }: Props) => {
  const handleSubmit = (values: IInsurance, formikHelpers: FormikHelpers<IInsurance>) => {
    onAdd(values)
    formikHelpers.resetForm()
    onClose()
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm, values }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form >
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent minW={[null, '4xl']} p='0.5rem' m='1.5rem' >
            <ModalCloseButton />
            <ModalHeader ml='10' fontWeight={700} fontSize={18} color='gray.800' py='6'>
              Insurance
            </ModalHeader>
            <ModalBody>
              <SimpleGrid columns={[1, null, 2]} spacing='16px' >
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='policyNumber'
                  label='Policy Number'
                />
                <FormikControl size='sm'
                  control='chakraSelect'
                  name='currency'
                  label='Currency'
                  options={CURRENCY_OPTIONS}
                />
                <FormikControl size='sm' control='chakraDatePicker' name='issuingDate' label='Issuing Date' />
                <FormikControl size='sm' control='chakraInput' name='issuingCompany' label='Issuing Company' />
                <FormikControl size='sm' control='chakraDatePicker' name='policyExpirationDate' label='Policy Expiration Date' />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  name='endorse'
                  label='Endorse'
                  options={[
                    { key: 'Yes', value: 'Yes' },
                    { key: 'No', value: 'No' }
                  ]}
                />
                <div />
              </SimpleGrid>
              <FormikControl size='sm' control='chakraTextarea' name='note' label='Notes' height='20' placeholder='Write here'/>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                fontSize={{ base: 14, lg: 16 }}
                type='submit'
                mr={3}
              >
                Add Insurance
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: IInsurance = {
  policyNumber: 0,
  currency: '',
  endorse: '',
  issuingDate: new Date(),
  issuingCompany: '',
  policyExpirationDate: new Date(),
  guaranteeControlCard: 0,
  note: ''
}

const validationSchema = yup.object({
  policyNumber: yup.number().required('Required'),
  currency: yup.string().required('Required'),
  endorse: yup.string().required('Required'),
  issuingDate: yup.date().typeError('Invalid Format Date').required('Required'),
  issuingCompany: yup.string().required('Required'),
  policyExpirationDate: yup.date().typeError('Invalid Format Date').required('Required'),
  note: yup.string()
})
