import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { type documents, type FOLDERS } from '../../types/documents'
import { DocumentList } from './DocumentList'

interface Props {
  sections: FOLDERS[]
  files: documents
}

export const PreviewDesign = ({ files, sections }: Props) => {
  return (
    <Tabs>
      <TabList>
        { sections.map(section =>
          <Tab
            key={section}
            fontSize={16}
            fontWeight={600}
            color='#B3B3B3'
            _selected={{ color: '#38B6FF', borderBottom: '2px solid #38B6FF' }}
            _hover={{ color: '#83D0FD', borderBottom: '2px solid #83D0FD' }}
          >
            {SECTIONS_CAPITALIZED[section]}
          </Tab>
        )}
      </TabList>
      <TabPanels>
        { sections.map(section =>
          <TabPanel key={section} pt={12}>
            <DocumentList
              documents={files?.[section] ?? []}
              folder={section}
              isPreview
            />
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}

enum SECTIONS_CAPITALIZED {
  BUSINESS = 'Business',
  FINANCIAL = 'Financial',
  LEGAL = 'Legal',
  OTHERS = 'Others'
}
