import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type Contract } from '../../../../../types/contract'
import { Item } from './Item'

export const BasicInfo = ({ contract }: { contract: Contract }) => {
  const CLIENT = [
    { left: 'ID', right: contract.client.id },
    { left: 'NAME', right: contract.client.name },
    { left: 'SENIOR BANKER', right: contract.client.seniorBanker },
    { left: 'ECONOMIC GROUP', right: contract.client.economicGroup },
    { left: 'BANKING BRANCH', right: contract.client.bankingBranch },
    { left: 'BANKER / EXECUTIVE', right: contract.client.banker }
  ]
  const CONTRACT = [
    { left: 'CONTRACT ID', right: contract.contractId },
    { left: 'CONTRACT TYPE', right: contract.contractType },
    { left: 'CURRENCY', right: contract.currency },
    { left: 'TOTAL AMOUNT', right: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true) },
    { left: 'PERCENTAGE BANK', right: `${contract.percentageBank}%` },
    { left: 'TERM', right: contract.term },
    { left: 'SIGN DATE', right: format(new Date(contract.contractSignDate), 'dd/MM/yyyy') },
    { left: 'EXPIRATION DATE', right: format(new Date(contract.contractExpirationDate), 'dd/MM/yyyy') },
    { left: 'CONTRACT LAW', right: contract.contractLaw },
    { left: 'GUARANTEES', right: contract.guarantees },
    { left: 'COVENANTS', right: contract.covenants },
    { left: 'FINANCING PURPOSE', right: contract.financingPurpose }
  ]
  const LAWYERS = [
    { let: 'NAME', right: contract.lawyers.externalLawyer.name },
    { let: 'EMAIL', right: contract.lawyers.externalLawyer.email },
    { let: 'PHONE', right: contract.lawyers.externalLawyer.phone },
    { let: 'OFFICE', right: contract.lawyers.externalLawyer.office },
    { let: 'NOTARY', right: contract.lawyers.externalLawyer.notary },
    { let: 'REPERTOIRE', right: contract.lawyers.externalLawyer.repertoire }
  ]

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            General Information
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          <Item info={CLIENT} title='1. Client'/>
          <Item info={CONTRACT} title='2. Contract'/>
          <Box
            bg='#E2E8F0'
            borderRadius={12}
            minW='300px'
            p='26px'
            h='fit-content'
            boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
          >
            <MainTitle title='3. Lawyers' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
            <Stack spacing={1}>
              <Text
                fontWeight={700}
                pb='4px'
                borderBottom='1px solid #B3B3B3'
                w='fit-content'
              >BANK RESPONSIBLE</Text>
              <Box display='flex' gap='8px' >
                <Text width='50%' fontWeight={700}>NAME</Text>
                <Text width='50%'>{contract.lawyers.bankResponsible.name}</Text>
              </Box>
              <Text
                fontWeight={700}
                pt='16px'
                pb='4px'
                borderBottom='1px solid #B3B3B3'
                w='fit-content'
              >EXTERNAL LAWYER</Text>
              { LAWYERS.map((item, index) => (
                <Box display='flex' gap='8px' key={index}>
                  <Text width='50%' fontWeight={700}>{item.let}</Text>
                  <Text width='50%'>{item.right}</Text>
                </Box>
              ))}
            </Stack>
          </Box>
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
