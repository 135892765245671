import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import FormikControl from '../../../../../../components/formComponents/FormikControl'
import * as yup from 'yup'
import { type IPaymentStructure } from '../../../../../../types/paymentStructure'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { CURRENCY_OPTIONS } from '../../../../../../components/utils/currency'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (bank: IPaymentStructure) => void
}

export const PaymentStructureModal = ({ isOpen, onClose, onAdd }: Props) => {
  const { contract } = useCreateContractContext()

  const handleSubmit = (values: IPaymentStructure, formikHelpers: FormikHelpers<IPaymentStructure>) => {
    onAdd(values)
    formikHelpers.resetForm()
    onClose()
  }

  const TRANCHE_OPTIONS = contract.financingStructures.map(tranche => {
    return { value: tranche.id, key: tranche.tranche }
  })
  const BANKS_OPTIONS = contract.banks.map(bank => {
    return { value: bank.id, key: bank.bankName }
  })

  const onTrancheChange = (e: React.ChangeEvent<HTMLSelectElement>, setFieldValue: any) => {
    const trancheId = e.target.value
    const tranche = contract.financingStructures.find(tranche => tranche.id === trancheId)
    if (tranche) {
      console.log(tranche)
      const { currency, amount } = tranche
      console.log(CURRENCY_OPTIONS.find(option => option.value === currency)?.value ?? '')
      setFieldValue('currency', CURRENCY_OPTIONS.find(option => option.value === currency)?.value ?? '')
      setFieldValue('trancheValue', amount)
    }
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm, values, setFieldValue }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent m='1.5rem' p='0.5rem' maxWidth='360px'>
            <ModalCloseButton />
            <ModalHeader fontWeight={700} fontSize={18} color='gray.800'>
              Payment Condition
            </ModalHeader>
            <ModalBody>
              <Stack spacing={1}>
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  name='tranche'
                  options={TRANCHE_OPTIONS}
                  label='Tranche'
                  handleChange={(e: any) => onTrancheChange(e, setFieldValue)}
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  suffix='%'
                  name={'paymentType'}
                  label='Payment Type'
                  options={[
                    { key: 'Installments', value: 'Installments' },
                    { key: 'Other', value: 'Other' }
                  ]}
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  suffix='%'
                  name={'bank'}
                  label='Bank'
                  options={BANKS_OPTIONS}
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  suffix='%'
                  name={'currency'}
                  label='Currency'
                  options={CURRENCY_OPTIONS}
                />
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  name={'installments'}
                  label='Number of Installments'
                  currency={values.currency}
                />
                <FormikControl
                  size='sm'
                  control='chakraCurrencyInput'
                  name={'trancheValue'}
                  label='Tranche Value'
                  currency={values.currency}
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                type='submit'
                mr={3}
                fontSize={{ base: 14, lg: 16 }}
              >
                Add Condition
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: IPaymentStructure = {
  bank: '',
  currency: '',
  installments: 0,
  paymentType: '',
  tranche: '',
  trancheValue: 0,
  id: ''
}

const validationSchema = yup.object({
  bank: yup.string().required('Required'),
  currency: yup.string().required('Required'),
  installments: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
  paymentType: yup.string().required('Required'),
  tranche: yup.string().required('Required'),
  trancheValue: yup.number().min(0.001, 'Must be greater than 0').required('Required')
})
