import { Box, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../../components/utils/currency'
import { type IInsurance } from '../../../../../../types/contract'

interface Props {
  insurance: IInsurance
}

export const InsuranceItem = ({ insurance }: Props) => {
  const BOX_ONE = [
    { label: 'POLICY NUMBER', value: formatNumber(insurance.policyNumber, insurance.currency as ICURRENCY) },
    { label: 'CURRENCY', value: insurance.currency },
    { label: 'ENDORSE', value: insurance.endorse },
    { label: 'ISSUING DATE', value: format(new Date(insurance.issuingDate), 'dd/MM/yyyy') },
    { label: 'ISSUING COMPANY', value: insurance.issuingCompany },
    { label: 'POLICY EXPIRATION DATE', value: format(new Date(insurance.policyExpirationDate), 'dd/MM/yyyy') }
  ]

  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='60%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        <MainTitle title='8. Insurance' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px' gap='8px' >
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748' width='60%'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='40%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        bg='#E2E8F0'
        borderRadius={12}
        width='40%'
        p='26px'
        h='fit-content'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        <MainTitle title='8. Notes' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
        <Text>{insurance.note}</Text>
      </Box>
    </Box>
  )
}
