import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { useContract } from '../../../../hooks/useContract'
import { usePayment } from '../../../../hooks/usePayment'
import { type Contract } from '../../../../types/contract'
import { type IPayment } from '../../../../types/payment'
import { HistoricalPayments } from './HistoricalPayments'
import { useState, useEffect, useCallback } from 'react'
import { ContractsToPay } from './ContractsToPay'

export const Payments = () => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const [payments, setPayments] = useState<IPayment[]>([])
  const { getContractsDividedByStatus } = useContract()
  const { getPayments } = usePayment()

  const loadData = useCallback(async () => {
    const resp = await Promise.all([
      getContractsDividedByStatus(),
      getPayments()
    ])
    setContracts(resp[0].APPROVED)
    setPayments(resp[1])
  }, [getContractsDividedByStatus, getPayments])

  useEffect(() => {
    async function init () {
      await loadData()
    }
    void init()
    return () => {
      setContracts([])
      setPayments([])
    }
  }, [loadData])

  const renderTabs = [
    { label: 'Payments Registry', component: <HistoricalPayments payments={payments} /> },
    { label: 'Add New Payment', component: <ContractsToPay contracts={contracts} /> }
  ]

  return (
    <Stack spacing='12' mb='4rem'>
      <MainTitle title='Payments' styles={{ marginLeft: 16 }} />
      <Tabs>
        <TabList>
          { renderTabs.map(({ label }) =>
            <Tab
              key={label}
              fontSize={18}
              fontWeight={600}
              color='#ADB8CC'
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: '#3D4D6A', borderBottom: '2px solid #3D4D6A' }}
              _hover={{ color: '#676C75', borderBottom: '2px solid #676C75' }}
            >{label}</Tab>
          )}
        </TabList>
        <TabPanels>
          { renderTabs.map(({ component }, index) => <TabPanel mt={12} key={index}>{component}</TabPanel>) }
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
