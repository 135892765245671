import { Accordion, Stack } from '@chakra-ui/react'
import { type Contract } from '../../../../../types/contract'
import { Banks } from '../../../BankExecutive/Contracts/ContractDetails/Banks'
import { BasicInfo } from '../../../BankExecutive/Contracts/ContractDetails/BasicInfo'
import { Covenants } from '../../../BankExecutive/Contracts/ContractDetails/Covenants'
import { Fees } from '../../../BankExecutive/Contracts/ContractDetails/Fees'
import { Guarantees } from '../../../BankExecutive/Contracts/ContractDetails/Guarantees'
import { Insurance } from '../../../BankExecutive/Contracts/ContractDetails/Insurance'
import { Obligations } from '../../../BankExecutive/Contracts/ContractDetails/Obligations'
import { PaymentStructure } from '../../../BankExecutive/Contracts/ContractDetails/PaymentStructure'
import { Tranches } from '../../../BankExecutive/Contracts/ContractDetails/Tranches'

interface Props {
  contract: Contract
}

export const ContractInformation = ({ contract }: Props) => {
  return (
    <Stack spacing={12 } mb='16'>
      <Accordion allowMultiple color='#2D3748' fontSize={14}>
        <BasicInfo contract={contract} />
        <Banks banks={contract.banks} />
        <Tranches financingStructures={contract.financingStructures}/>
        <PaymentStructure contract={contract} />
        <Guarantees guarantees={contract.guaranteesDetails} />
        <Insurance insurance={contract.insurance} />
        <Covenants covenants={contract.covenantsList} />
        <Fees fees={contract.fees} />
        <Obligations
          obligationsNotToDo={contract.obligationsNotToDo}
          obligationsToDo={contract.obligationsToDo}
        />
      </Accordion>
    </Stack>
  )
}
