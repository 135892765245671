import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid } from '@chakra-ui/react'
import { type IBank } from '../../../../../types/contract'
import { Item } from './Item'

interface Props {
  banks: IBank[]
}

export const Banks = ({ banks }: Props) => {
  const BANKS_OPTIONS = banks.map(bank => {
    return [
      { left: 'BANK NAME', right: bank.bankName },
      { left: 'LOAN DISTRIBUTION', right: `${bank.loanDistribution}%` },
      { left: 'ROLE', right: bank.role },
      { left: 'POSITION', right: bank.position },
      { left: 'RESPONSIBLE NAME', right: bank.responsibleName },
      { left: 'PHONE', right: bank.phone },
      { left: 'EMAIL', right: bank.email }
    ]
  })
  return (
    <AccordionItem >
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Banks
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          { BANKS_OPTIONS.map((bank, index) =>
            <Item
              key={`bank-${index}`}
              info={bank}
              title={`4. Bank ${index + 1}`}
            />
          )}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
