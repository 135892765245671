import { Box, Button, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { scrollToTop } from '../../../../../../components/utils/utils'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { useChakraToast } from '../../../../../../hooks/useChakraToast'
import { type IBank, type IFinancingStructure } from '../../../../../../types/contract'
import { Banks } from './Bank/Banks'
import { FinancingStructures } from './FinancingStructure/FinancingStructures'
import { v4 as uuidv4 } from 'uuid'

export const BanksFinances = () => {
  const { setContract, prevStep, nextStep, contract } = useCreateContractContext()
  const [financingStructures, setFinancingStructures] = useState<IFinancingStructure[]>(contract.financingStructures)
  const [banks, setBanks] = useState<IBank[]>(contract.banks)
  const { showToast } = useChakraToast()

  const onAddBank = (bank: IBank) => {
    bank.id = uuidv4()
    setBanks(prev => [...prev, bank])
  }
  const onDeleteBank = (index: number) => setBanks(prev => prev.filter((_, i) => i !== index))

  const onAddFinancingStructure = (financingStructure: IFinancingStructure) => {
    financingStructure.id = uuidv4()
    setFinancingStructures(prev => [...prev, financingStructure])
  }
  const onDeleteFinancingStructure = (index: number) => setFinancingStructures(prev => prev.filter((_, i) => i !== index))

  const onSubmit = () => {
    if (banks.length === 0) {
      showToast('error', 'Please add at least one bank')
      return
    }
    if (financingStructures.length === 0) {
      showToast('error', 'Please add at least one financing structure')
      return
    }
    setContract(prev => ({ ...prev, banks, financingStructures }))
    nextStep()
    scrollToTop()
  }

  return (
    <Stack spacing={16} mb={12}>
      <Stack spacing={8}>
        <MainTitle title='4. Banks' variant='subsection' styles={{ maxWidth: '100%' }} />
        <Banks banks={banks} onAdd={onAddBank} onDelete={onDeleteBank} />
      </Stack>
      <Stack spacing={8}>
        <MainTitle title='5.  Financing Structure' variant='subsection' styles={{ maxWidth: '100%' }} />
        <FinancingStructures
          financingStructures={financingStructures}
          onAdd={onAddFinancingStructure}
          onDelete={onDeleteFinancingStructure}
        />
      </Stack>
      <Box width='100%' textAlign='right'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button ml='12px' colorScheme='teal' onClick={onSubmit} fontSize={{ base: 14, lg: 16 }}>
          Next step
        </Button>
      </Box>
    </Stack>
  )
}
