import { Box, Text } from '@chakra-ui/react'
import { type IBank } from '../../../../../../../types/contract'

interface Props {
  bank: IBank
}

export const BankItem = ({ bank }: Props) => {
  const BOX_ONE = [
    { label: 'Bank name', value: bank.bankName },
    { label: 'Loan distribution', value: `${bank.loanDistribution}%` },
    { label: 'Role', value: bank.role },
    { label: 'Position', value: bank.position }
  ]
  const BOX_TWO = [
    { label: 'Responsible name', value: bank.responsibleName },
    { label: 'Phone', value: bank.phone },
    { label: 'Email', value: bank.email }
  ]
  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='47%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px' >
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='50%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        backgroundColor='gray.200'
        p='16px'
        width='47%'
        height='fit-content'
        borderRadius={12}
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_TWO.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px'>
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='50%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
