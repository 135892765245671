import { Route, Routes } from 'react-router-dom'
import Dashboard from '../components/dashboard/Dashboard'
import { Contracts } from '../pages/Dashboard/BankExecutive/Contracts/Contracts'
import { ContractDetail } from '../pages/Dashboard/BankExecutive/Contracts/ContractDetails/ContractDetail'
import { ContractCreation } from '../pages/Dashboard/BankExecutive/Contracts/ContractCreation/ContractCreation'
import { Logs } from '../pages/Dashboard/BankExecutive/Logs/Logs'
import { CreateLog } from '../pages/Dashboard/BankExecutive/Logs/CreateLog'
import { Payments } from '../pages/Dashboard/BankExecutive/Payments/Payments'
import { PayAmortization } from '../pages/Dashboard/BankExecutive/Payments/PayAmortization'
import { SearchEnginePage } from '../pages/Dashboard/BankExecutive/SearchEngine/SearchEnginePage'
import { SEContractDetails } from '../components/searchEngine/SEContractDetails'

const BankExecutiveRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<Dashboard />}>
        <Route path='contracts' element={<Contracts />} />
        <Route path='contracts/:id' element={<ContractDetail />} />
        <Route path='payments' element={<Payments />} />
        <Route path='payments/pay' element={<PayAmortization />} />
        <Route path='logs' element={<Logs />} />
        <Route path='logs/create' element={<CreateLog />} />
        <Route path='search' element={<SearchEnginePage />} />
        <Route path='search/:id' element={<SEContractDetails />} />

        <Route path='newContract' element={<ContractCreation />} />
      </Route>
    </Routes>
  )
}

export default BankExecutiveRoutes
