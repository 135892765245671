import { Text } from '@chakra-ui/react'
import { type document, type FOLDERS } from '../../types/documents'
import { hideLoader, showLoader } from '../loader/Loader'
import { v4 as uuidv4 } from 'uuid'
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { storage } from '../../firebase'

interface Props {
  folder: FOLDERS
  onLoadFile?: (file: document, folder: FOLDERS) => void
}

export const UploadButton = ({ folder, onLoadFile = (file: document, folder: FOLDERS) => {} }: Props) => {
  const onUpload = async ({ currentTarget, target }: React.ChangeEvent<HTMLInputElement>) => {
    const files = currentTarget.files
    if (files) {
      showLoader()
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const fileName = `${uuidv4()}-${file.name}`
        const storageRef = ref(storage, `uploads/${fileName}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        await uploadTask
        const url = await getDownloadURL(uploadTask.snapshot.ref)
        const document: document = {
          _id: fileName,
          fileName: file.name,
          url
        }
        onLoadFile(document, folder)
      }
      hideLoader()
    }
    if (target.value) {
      target.value = ''
    }
  }

  return (
    <label htmlFor={`upload-${folder}`} style={{ width: 'fit-content' }}>
      <Text
        fontSize={12}
        color='#38B6FF'
        fontWeight={600}
        cursor='pointer'
        _hover={{ textDecoration: 'underline' }}
      >
        Upload document
      </Text>
      <input
        id={`upload-${folder}`}
        style={{ display: 'none' }}
        accept='image/*, audio/*, video/*, .xlsx, .xls, .csv, .pdf, .doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        multiple
        type="file"
        onChange={onUpload}
      />
    </label>

  )
}
