import { createContext, useContext, useState } from 'react'
import { type Contract } from '../types/contract'

interface CreateContractContextProps {
  currentStep: number
  nextStep: () => void
  prevStep: () => void
  tabs: string[]
  contract: Contract
  setContract: React.Dispatch<React.SetStateAction<Contract>>
}

// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
export const CreateContractContext = createContext<CreateContractContextProps>({} as CreateContractContextProps)

export const CreateContractProvider = ({ children }: { children: React.ReactNode }) => {
  const [contract, setContract] = useState<Contract>(INITIAL_VALUES)
  const [currentStep, setCurrentStep] = useState(0)
  console.log('contract', contract)
  const nextStep = () => setCurrentStep(prev => prev + 1)
  const prevStep = () => setCurrentStep(prev => prev - 1)

  const tabs = [
    'General Information ',
    'Banks / Finances',
    'Payment Structure',
    'Guarantees',
    'Insurance',
    'Covenants',
    'Fees',
    'Obligations',
    'Documentation'
  ]

  return (
    <CreateContractContext.Provider
      value={{
        currentStep,
        nextStep,
        prevStep,
        tabs,
        contract,
        setContract
      }}
    >
      {children}
    </CreateContractContext.Provider>
  )
}

const INITIAL_VALUES: Contract = {
  contractId: '',
  contractSignDate: new Date(),
  contractExpirationDate: new Date(),
  contractType: '',
  currency: '',
  contractLaw: '',
  totalAmount: 0,
  guarantees: '',
  percentageBank: 0,
  covenants: '',
  financingPurpose: '',
  term: '',
  client: {
    id: '',
    name: '',
    economicGroup: '',
    bankingBranch: '',
    seniorBanker: '',
    banker: ''
  },
  lawyers: {
    bankResponsible: {
      name: ''
    },
    externalLawyer: {
      name: '',
      phone: '',
      email: '',
      office: '',
      notary: '',
      repertoire: ''
    }
  },
  banks: [],
  financingStructures: [],
  paymentStructures: [],
  guaranteesDetails: [],
  insurance: [],
  covenantsList: [],
  fees: {
    type: '',
    paymentDate: new Date(),
    currency: '',
    amount: 0,
    quantity: 0,
    period: '',
    note: ''
  },
  obligationsToDo: [],
  obligationsNotToDo: [],
  documents: {
    BUSINESS: [],
    FINANCIAL: [],
    LEGAL: [],
    OTHERS: []
  },
  status: 'PENDING'
}

export const useCreateContractContext = (): CreateContractContextProps => {
  const context = useContext(CreateContractContext)
  return context
}
