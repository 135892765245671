import React from 'react'
import PropTypes from 'prop-types'
import { Field, type FieldProps } from 'formik'
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputRightElement,
  Icon,
  InputGroup
} from '@chakra-ui/react'
import { MdVisibilityOff, MdVisibility } from 'react-icons/md'

export interface ChakraPasswordInputProps {
  label: string
  name: string
  [propName: string]: any
}

function ChakraPasswordInput (props: ChakraPasswordInputProps) {
  const [show, setShow] = React.useState(false)
  const { label, name, ...rest } = props

  const handleClick = () => { console.log('hola'); setShow(!show) }

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(form.errors[name] && form.touched[name])}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} fontWeight={600} htmlFor={name} color='gray.700'>{label}</FormLabel>
          <InputGroup>
            <Input
              id={name}
              {...rest}
              {...field}
              type={show ? 'text' : 'password'}
            />
            <InputRightElement >
              <Icon
                onClick={handleClick}
                as={show ? MdVisibilityOff : MdVisibility}
                boxSize={5}
                style={{ cursor: 'pointer' }}
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{form.errors[name]}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

ChakraPasswordInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default ChakraPasswordInput
