import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text
} from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import FormikControl from '../../../../../../../components/formComponents/FormikControl'
import { type IFinancingStructure } from '../../../../../../../types/contract'
import * as yup from 'yup'
import { CURRENCY_OPTIONS } from '../../../../../../../components/utils/currency'
import * as ExcelJS from 'exceljs'
import { useState } from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (financingStructure: IFinancingStructure) => void
}

export const FinancingStructureModal = ({ isOpen, onClose, onAdd }: Props) => {
  const [amortizationTable, setAmortizationTable] = useState<any[]>([])
  const handleSubmit = (values: IFinancingStructure, formikHelpers: FormikHelpers<IFinancingStructure>): void => {
    onAdd({
      ...values,
      amortizationTable
    })
    formikHelpers.resetForm()
    onClose()
  }

  const onUpload = async ({ currentTarget, target }: React.ChangeEvent<HTMLInputElement>) => {
    const files = currentTarget.files
    if (files) {
      console.log(files[0])
      try {
        const workbook = new ExcelJS.Workbook()
        const reader = new FileReader()

        reader.readAsArrayBuffer(files[0])
        reader.onload = async () => {
          const buffer = reader.result as ArrayBuffer
          await workbook.xlsx.load(buffer)
          const amortizationTable = []
          for (let i = 0; i < workbook.worksheets[0].rowCount; i++) {
            const row = workbook.worksheets[0].getRow(i + 1)
            if (i === 0) continue
            if (row.values.length !== 6 && row.values.length !== 0) {
              console.log(row.values)
              alert('Invalid file format')
              break
            }
            if (row.values.length === 6) {
              const values = row.values as any
              const paymentDate = values[1].result || values[1]
              const beginningBalance = values[2].result || values[2]
              const payment = values[3].result || values[3]
              const principal = values[4].result || values[4]
              const endingBalance = values[5].result || values[5]
              amortizationTable.push({
                paymentDate: paymentDate instanceof Date ? paymentDate : new Date(paymentDate),
                beginningBalance,
                payment,
                principal,
                endingBalance
              })
            }
          }
          setAmortizationTable(amortizationTable)
        }
      } catch (error) {
        console.log(error)
      }
    }
    if (target.value) {
      target.value = ''
    }
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm, values }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent m='1.5rem' maxWidth='360px'>
            <ModalCloseButton />
            <ModalHeader fontWeight={700} fontSize={18} color='gray.800'>
              Tranche Information
            </ModalHeader>
            <ModalBody>
              <Stack spacing={2}>
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Tranche'
                  name={'tranche'}
                  placeholder='Tranche'
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  label='Financing Type'
                  name={'financingType'}
                  options={[
                    { key: 'Long-term', value: 'Long-term' },
                    { key: 'Short-term', value: 'Short-term' }
                  ]}
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  label='Currency'
                  name={'currency'}
                  options={CURRENCY_OPTIONS}
                />
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  label='Interest Rate (%)'
                  currency={values.currency}
                  name={'rate'}
                  suffix='%'
                />
                <FormikControl
                  size='sm'
                  control='chakraDatePicker'
                  label='First Payment Date'
                  name={'firstPaymentDate'}
                  placeholder='First Payment Date'
                />
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  label='Term (Years)'
                  currency={values.currency}
                  name={'term'}
                  placeholder='Years'
                />
                <FormikControl
                  size='sm'
                  control='chakraCurrencyInput'
                  label='Amount'
                  currency={values.currency}
                  name={'amount'}
                  placeholder='Amount'
                />
                <FormikControl
                  size='sm'
                  control='chakraSelect'
                  label='Constant payments?'
                  name={'isConstantPayments'}
                  options={[
                    { key: 'Yes', value: 'yes' },
                    { key: 'No', value: 'no' }
                  ]}
                />
                {values.isConstantPayments === 'no' &&
                  (<label htmlFor={'upload-tranche'} style={{ width: 'fit-content' }}>
                  <Text
                    fontSize={12}
                    color='#38B6FF'
                    fontWeight={600}
                    cursor='pointer'
                    _hover={{ textDecoration: 'underline' }}
                  >
                    Upload amortization table
                  </Text>
                  <input
                    id={'upload-tranche'}
                    style={{ display: 'none' }}
                    accept='.xlsx, .xls'
                    type="file"
                    onChange={onUpload}
                  />
                    {
                      amortizationTable.length > 0 &&
                      <Text fontSize={12} color='#28b883' fontWeight={600}>
                        {amortizationTable.length} rows uploaded
                      </Text>
                    }
                </label>)
                }
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                type='submit'
                fontSize={{ base: 14, lg: 16 }}
                mr={3}
              >
                Add Tranche
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: IFinancingStructure = {
  id: '',
  amount: 0,
  currency: '',
  financingType: '',
  term: 0,
  tranche: '',
  rate: 0,
  firstPaymentDate: new Date(),
  isConstantPayments: 'yes'
}

const validationSchema = yup.object({
  amount: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
  currency: yup.string().required('Required'),
  financingType: yup.string().required('Required'),
  rate: yup.number().min(0.001, 'Must be greater than 0').max(100, 'Must be less than 100%').required('Required'),
  term: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
  tranche: yup.string().required('Required'),
  isConstantPayments: yup.string().required('Required')
})
