import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type IInsurance } from '../../../../../types/contract'
import { Item } from './Item'

export const Insurance = ({ insurance }: { insurance: IInsurance[] }) => {
  const INSURANCE = insurance.map(insurance => [
    { left: 'POLICY NUMBER', right: formatNumber(insurance.policyNumber, insurance.currency as ICURRENCY) },
    { left: 'CURRENCY', right: insurance.currency },
    { left: 'ENDORSE', right: formatNumber(insurance.endorse, insurance.currency as ICURRENCY) },
    { left: 'ISSUING DATE', right: format(new Date(insurance.issuingDate), 'dd/MM/yyyy') },
    { left: 'ISSUING COMPANY', right: formatNumber(insurance.issuingCompany, insurance.currency as ICURRENCY) },
    { left: 'POLICY EXPIRATION DATE', right: format(new Date(insurance.policyExpirationDate), 'dd/MM/yyyy') }
  ])

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Insurance
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid spacing={5}>
          { INSURANCE.map((guarantee, index) =>
            <SimpleGrid key={index} minChildWidth='300px' spacing={5}>
              <Item info={guarantee} title={`8. Insurance ${index + 1}`}/>
              <Box
                bg='#E2E8F0'
                borderRadius={12}
                minWidth='45%'
                p='26px'
                h='fit-content'
                boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
              >
                <MainTitle title={`8. Notes ${index + 1}`} variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
                <Text>{insurance[index].note}</Text>
              </Box>
            </SimpleGrid>
          )}

        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
