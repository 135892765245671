import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { type Contract } from '../../types/contract'
import { type ICURRENCY } from '../utils/currency'
import { AmortizationHOC } from './AmortizationHOC'
import Amortization from './AmortizationTable'
import StaticAmortization from './StaticAmortizationTable'

interface Props {
  contract: Contract
}

export const AmortizationTrancheTabs = ({ contract }: Props) => {
  return (
    <Tabs id={`amortization-${contract._id}`} key={`amortization-${contract._id}`}>
      <TabList>
        { contract.financingStructures.map((tranche, index) =>
          <Tab
            key={tranche.id}
            fontSize={16}
            fontWeight={600}
            color='#B3B3B3'
            _selected={{ color: '#38B6FF', borderBottom: '2px solid #38B6FF' }}
            _hover={{ color: '#83D0FD', borderBottom: '2px solid #83D0FD' }}
          >
            Tranche #{index + 1}
          </Tab>
        )}
      </TabList>
      <TabPanels>
        { contract.financingStructures.map((tranche, index) =>
          <TabPanel key={index}>
            <AmortizationHOC trancheId={tranche.id}>
              { ({ payments }) =>
                <>{tranche.isConstantPayments === 'no'
                  ? <StaticAmortization
                    payments={payments}
                    tranche={tranche}
                    currency={tranche.currency as ICURRENCY}
                  />
                  : <Amortization
                    payments={payments}
                    amount={tranche.amount}
                    rate={tranche.rate / 100}
                    startDate={new Date(tranche.firstPaymentDate)}
                    years={tranche.term}
                    currency={tranche.currency as ICURRENCY}
                  />
                }
                </>
              }
            </AmortizationHOC>
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  )
}
