export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

export function localeDate (date: Date) {
  return date.setTime(date.getTime() + date.getTimezoneOffset() * 60 * 1000)
}
