import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, Icon, IconButton, Stack, Text } from '@chakra-ui/react'
import { scrollToTop } from '../../../../../../components/utils/utils'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { type IGuarantees } from '../../../../../../types/contract'
import { v4 as uuidv4 } from 'uuid'
import { useState } from 'react'
import { DeleteIcon } from '@chakra-ui/icons'
import { useDialog } from '../../../../../../hooks/useDialog'
import { useChakraToast } from '../../../../../../hooks/useChakraToast'
import { GuaranteesModal } from './GuaranteesModal'
import { MdAdd } from 'react-icons/md'
import { GuaranteeItem } from './GuaranteeItem'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'

export const Guarantees = () => {
  const { contract, setContract, nextStep, prevStep } = useCreateContractContext()
  const [guaranteesDetails, setGuaranteesDetails] = useState<IGuarantees[]>(contract.guaranteesDetails)
  const { closeDialog, openDialog, isOpen } = useDialog()
  const { showToast } = useChakraToast()

  const onSubmit = () => {
    if (guaranteesDetails.length === 0) {
      showToast('error', 'Please add at least one covenant')
      return
    }
    setContract(prev => ({ ...prev, guaranteesDetails }))
    nextStep()
    scrollToTop()
  }

  const onAdd = (guarantee: IGuarantees) => {
    guarantee.id = uuidv4()
    setGuaranteesDetails(prev => [...prev, guarantee])
    closeDialog()
  }

  const onDelete = (index: number) => {
    setGuaranteesDetails(prev => prev.filter((_, i) => i !== index))
  }

  return (
    <Stack spacing={8}>
      <GuaranteesModal
        isOpen={isOpen}
        onClose={closeDialog}
        onAdd={onAdd}
      />
      <MainTitle title='7. Guarantees' variant='subsection' styles={{ maxWidth: '100%' }} />
      { guaranteesDetails.length === 0
        ? <Text fontSize={14}>Please add Guarantees</Text>
        : <Accordion allowMultiple>
          {guaranteesDetails.map((guarantee, index) =>
            <AccordionItem key={index}>
              <Box display='flex' justifyContent='space-between' gap={2}>
                <AccordionButton width='100%'>
                  <Box as="span" flex='1' textAlign='left'>
                    <h2> Guarantee #{index + 1} </h2>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <IconButton
                  backgroundColor='transparent'
                  aria-label='delete'
                  color='#505050'
                  onClick={() => onDelete(index)}
                  icon={<Icon as={DeleteIcon} boxSize={5} />}
                />
              </Box>
              <AccordionPanel pb={4}>
                <GuaranteeItem guarantee={guarantee} />
              </AccordionPanel>
            </AccordionItem>
          )}
      </Accordion>
    }
      <Button
        rightIcon={<Icon as={MdAdd} boxSize={4} />}
        maxWidth='fit-content'
        fontSize={12}
        onClick={openDialog}
        py='8px'
        px='24px'
      >
        Add
      </Button>
      <Box width='100%' textAlign='right' mt='8px'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button ml='12px' colorScheme='teal' onClick={onSubmit} fontSize={{ base: 14, lg: 16 }}>
          Next step
        </Button>
      </Box>
    </Stack>
  )
}
