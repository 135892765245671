import { createUserWithEmailAndPassword, getAuth, sendEmailVerification, sendPasswordResetEmail, signInWithEmailAndPassword } from '@firebase/auth'
import { type IUser } from '../../types/user'
import { addUser } from '../userService'

const auth = getAuth()

export const createNormalAccount = async (newUser: IUser, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, newUser.email, password)
    const user = userCredential.user
    await sendEmailVerification(user)
    await addUser(newUser)
    return true
  } catch (error) {
    console.error('Error creating account:', error)
    return false
  }
}

export const normalAccountLogin = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    const user = userCredential.user
    return user
  } catch (error: any) {
    console.error('Error creating account:', {
      message: error.message,
      code: error.code
    })
    if (error.code === 'auth/user-not-found') {
      return 'User not found'
    }
    if (error.code === 'auth/wrong-password') {
      return 'Wrong password'
    }
    if (error.code === 'auth/too-many-requests') {
      return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.'
    }

    return error.code
  }
}

export const logout = async () => {
  try {
    await auth.signOut()
    return true
  } catch (error) {
    console.log(error)
    return false
  }
}

export const passwordReset = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    return true
  } catch (error) {
    console.error('Error sending password reset email:', error)
    return false
  }
}
