import { DeleteIcon, Icon } from '@chakra-ui/icons'
import { Box, IconButton, Text } from '@chakra-ui/react'
import { deleteObject, ref } from 'firebase/storage'
import { MdInsertDriveFile } from 'react-icons/md'
import { storage } from '../../firebase'
import { type FOLDERS, type document } from '../../types/documents'
import { hideLoader, showLoader } from '../loader/Loader'

interface Props {
  documents: document[]
  folder: FOLDERS
  onDelete?: (document: document, folder: FOLDERS) => void
  isPreview: boolean
}

export const DocumentList = ({ isPreview, documents, folder, onDelete = (document: document, folder: FOLDERS) => {} }: Props) => {
  const handleDelete = async (document: document) => {
    const desertRef = ref(storage, `uploads/${document._id}`)
    showLoader()
    await deleteObject(desertRef)
    onDelete(document, folder)
    hideLoader()
  }
  return (
    <Box>
      { documents.map(document =>
        <Box key={document._id} display='flex' justifyContent='space-between' alignItems='center'>
          <Box display='flex' gap={16}>
            <Icon ml='8' as={MdInsertDriveFile} boxSize={6} color='#3D4D6A' />
            <Text
              color='#3D4D6A'
              fontSize={14}
              cursor='pointer'
              onClick={() => window.open(document.url, '_blank')}
              _hover={{ textDecoration: 'underline' }}
            >
              {document.fileName}
            </Text>
          </Box>
          { !isPreview
            ? <IconButton
            backgroundColor='transparent'
            aria-label='delete'
            onClick={async () => await handleDelete(document)}
            icon={<Icon as={DeleteIcon} boxSize={6} color='#505050' />}
          />
            : null
          }
        </Box>
      )}
    </Box>
  )
}
