import { AbsoluteCenter, Box, Button, Divider, Stack, Text } from '@chakra-ui/react'
import { LoginLayout } from '../../layout/login/LoginLayout'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useLogin } from '../../hooks/useLogin'
import FormikControl, { type FormikControlProps } from '../formComponents/FormikControl'
import { Icon } from '@chakra-ui/icons'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'

const INITIAL_VALUES = {
  email: '',
  password: ''
}

const Inputs = [
  {
    control: 'chakraInput',
    type: 'email',
    name: 'email',
    label: 'Email',
    placeholder: 'Enter an email'
  },
  {
    control: 'chakraPasswordInput',
    type: 'password',
    name: 'password',
    label: 'Password',
    placeholder: 'Enter a password'
  }
]

const LoginForm = () => {
  const { googleLogin, handlerNormalAccountLogin } = useLogin()
  const navigate = useNavigate()

  const onNormalAccountLogin = async (values: typeof INITIAL_VALUES) => {
    await handlerNormalAccountLogin(values.email, values.password)
  }

  return (
    <LoginLayout>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onNormalAccountLogin}
        validationSchema={validationSchema}
      >
        {() =>
          <Form style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack w='md' spacing='8' p={4}>
              <Stack spacing='3'>
                <Text fontSize={48} pb='6' color='gray.700' fontWeight={500}>Welcome!</Text>
                  { Inputs.map((input) =>
                      <FormikControl
                        key={input.name}
                        {...input as FormikControlProps}
                      />
                  )}
              </Stack>
              <Stack spacing='3'>
                <Button type='submit' colorScheme='teal' >
                  Sign in
                </Button>
                <Button
                  colorScheme='teal'
                  variant='outline'
                  rightIcon={<Icon as={FcGoogle} boxSize={5} />}
                  onClick={googleLogin}
                >
                  Sign in with Google
                </Button>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Text fontSize='sm' color='gray.500' maxWidth='fit-content'>
                    Forgot your password?&nbsp;
                  </Text>
                  <Text
                    color='#38B6FF'
                    fontSize='sm'
                    maxWidth='fit-content'
                    onClick={() => navigate('/recover-password')}
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Click here
                  </Text>
                </Box>
                <Box position='relative' padding='10' color='#3D4D6A'>
                  <Divider />
                  <AbsoluteCenter bg='white' px='4' fontSize={14}>
                    or
                  </AbsoluteCenter>
                </Box>
                <Box alignItems='center' display='flex' gap={2} flexDirection='column' fontSize={14}>
                  <Text>Don&apos;t have an account yet?</Text>
                  <Button
                    colorScheme='teal'
                    variant='ghost'
                    fontSize={20}
                    maxWidth='fit-content'
                    _hover={{ bg: 'teal.500', color: 'white' }}
                    onClick={() => navigate('/create-account')}
                  >
                    Create account
                  </Button>
                </Box>
              </Stack>
            </Stack>
          </Form>
        }
      </Formik>
    </LoginLayout>
  )
}

export default LoginForm

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup.string().required('Required')
})
