import { Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { useContract } from '../../../../hooks/useContract'
import { type Contract, type CONTRACT_STATUS } from '../../../../types/contract'
import { ApprovedContracts } from './ApprovedContracts'
import { PendingContracts } from './PendingContracts'

const INITIAL_STATE = {
  APPROVED: [],
  PENDING: [],
  REJECTED: []
}

export const RegisteredContracts = () => {
  const { getContractsDividedByStatus, handleValidateContract } = useContract()
  const [contracts, setContracts] = useState< Record<CONTRACT_STATUS, Contract[]>>(INITIAL_STATE)

  const loadContracts = async () => {
    setContracts(await getContractsDividedByStatus())
  }

  useEffect(() => {
    async function init () {
      await loadContracts()
    }
    void init()

    return () => { setContracts(INITIAL_STATE) }
  }, [getContractsDividedByStatus])

  const onValidateContract = async (
    contractId: string,
    isValid: boolean,
    rejectedNote: string
  ) => {
    await handleValidateContract(contractId, isValid, rejectedNote)
    await loadContracts()
  }

  const renderTabs = [
    { label: 'Approved', component: <ApprovedContracts contracts={contracts.APPROVED} /> },
    {
      label: 'Pending',
      component:
      <PendingContracts
        contracts={contracts.PENDING}
        onValidateContract={onValidateContract}
      />
    }
  ]

  return (
    <Stack spacing='12' mb='4rem'>
      <MainTitle title='Registered Contracts' />
      <Tabs>
        <TabList>
          { renderTabs.map(({ label }) =>
            <Tab
              key={label}
              fontSize={18}
              fontWeight={600}
              color='#ADB8CC'
              _focus={{ boxShadow: 'none' }}
              _selected={{ color: '#3D4D6A', borderBottom: '2px solid #3D4D6A' }}
              _hover={{ color: '#676C75', borderBottom: '2px solid #676C75' }}
            >{label}</Tab>
          )}
        </TabList>
        <TabPanels>
          { renderTabs.map(({ component }, index) => <TabPanel mt={12} key={index}>{component}</TabPanel>) }
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
