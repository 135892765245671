import { DeleteIcon, Icon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, IconButton, Stack, Text } from '@chakra-ui/react'
import { MdAdd } from 'react-icons/md'
import { useDialog } from '../../../../../../../hooks/useDialog'
import { type IBank } from '../../../../../../../types/contract'
import { BankItem } from './BankItem'
import { BanksModal } from './BanksModal'

interface Props {
  banks: IBank[]
  onAdd: (bank: IBank) => void
  onDelete: (index: number) => void
}

export const Banks = ({ banks, onAdd, onDelete }: Props) => {
  const { closeDialog, openDialog, isOpen } = useDialog()

  return (
    <Stack spacing={8}>
    <BanksModal
      isOpen={isOpen}
      onClose={closeDialog}
      onAdd={onAdd}
    />
    { banks.length === 0
      ? <Text fontSize={14}>Please add bank information</Text>
      : <Accordion allowMultiple>
        {banks.map((bank, index) =>
            <AccordionItem key={index}>
              <Box display='flex' justifyContent='space-between' gap={2}>
                <AccordionButton width='100%'>
                  <Box as="span" flex='1' textAlign='left'>
                    <h2> Bank #{index + 1} </h2>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <IconButton
                  backgroundColor='transparent'
                  aria-label='delete'
                  color='#505050'
                  onClick={() => onDelete(index)}
                  icon={<Icon as={DeleteIcon} boxSize={5} />}
                />
              </Box>
              <AccordionPanel pb={4}>
                <BankItem bank={bank} />
              </AccordionPanel>
            </AccordionItem>
        )}
      </Accordion>
    }
      <Button
        rightIcon={<Icon as={MdAdd} boxSize={4} />}
        maxWidth='fit-content'
        fontSize={12}
        onClick={openDialog}
        py='8px'
        px='24px'
      >
        Add Bank
      </Button>
    </Stack>
  )
}
