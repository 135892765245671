import axios from 'axios'

const httpService = axios.create({
  baseURL: 'https://syndicated-loans-prototype-default-rtdb.firebaseio.com/',
  timeout: 60000
})

httpService.interceptors.response.use(
  (response) => response,
  (error) => {
    const expectedError =
      error.response &&
      error.response.status >= 400 &&
      error.response.status < 500

    if (!expectedError) {
      console.error('interceptor-error:', error)
      return Promise.reject(new Error('Unexpected Error Ocurred'))
    }
    return Promise.reject(error.response.data)
  }
)

export default httpService
