import { Box } from '@chakra-ui/react'
import type React from 'react'
import { useCreateContractContext } from '../../../../../contexts/CreateContractContext'

interface Props {
  children: React.ReactNode
  step: number
}

export const StepperItem = ({ children, step }: Props) => {
  const { currentStep } = useCreateContractContext()

  if (step === currentStep) return <Box mt={2} width='100%'>{children}</Box>
  return null
}
