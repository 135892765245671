export const currencies: ICurrencies = {
  USD: {
    thousandSeparator: ',',
    decimalSeparator: '.'
  },
  EUR: {
    thousandSeparator: '.',
    decimalSeparator: ','
  },
  GBP: {
    thousandSeparator: ',',
    decimalSeparator: '.'
  },
  UF: {
    thousandSeparator: '.',
    decimalSeparator: ','
  },
  CLP: {
    thousandSeparator: '.',
    decimalSeparator: ',',
    decimalScale: 0
  }
}

export type ICURRENCY = 'USD' | 'EUR' | 'GBP' | 'UF' | 'CLP'

export type ICurrencies = Record<ICURRENCY, {
  thousandSeparator: string
  decimalSeparator: string
  decimalScale?: number
}>

export const CURRENCY_OPTIONS: Array<{ value: ICURRENCY, key: ICURRENCY }> = [
  { value: 'USD', key: 'USD' },
  { value: 'EUR', key: 'EUR' },
  { value: 'GBP', key: 'GBP' },
  { value: 'UF', key: 'UF' },
  { value: 'CLP', key: 'CLP' }
]

export const formatNumber = (
  numero: string | number = '',
  currency: ICURRENCY,
  isMonetary: boolean = false
) => {
  const { thousandSeparator, decimalSeparator, decimalScale } = currencies[currency]
  const separateNumber = numero.toString().split('.')
  const group = separateNumber[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousandSeparator)
  if (!isMonetary && separateNumber.length > 1) return group + (decimalScale ? decimalSeparator + separateNumber[1] : '')
  if (isMonetary && !(separateNumber.length > 1)) return '$' + group + (decimalScale ? decimalSeparator + '00' : '')
  if (separateNumber.length > 1) return group + (decimalScale ? decimalSeparator + separateNumber[1] : '')
  return group
}
