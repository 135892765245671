import { Box, SimpleGrid, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import FormikControl from '../formComponents/FormikControl'
import { SearchEngineTable } from './SearchEngineTable'
import { SearchEngineTitle } from './SearchEngineTitle'

export interface IFilters {
  economicGroup: string
  clientName: string
  contractId: string
  contractType: string
  banker: string
  contractSignDate: Date
}

export const INITIAL_FILTER_VALUES: IFilters = {
  economicGroup: '',
  clientName: '',
  contractId: '',
  contractType: '',
  banker: '',
  contractSignDate: new Date()
}

export const SearchEngine = () => {
  const getModifiedFieldCount = (values: IFilters) => {
    let count = 0
    Object.keys(values).forEach(key => {
      if (values[key as keyof IFilters] !== INITIAL_FILTER_VALUES[key as keyof IFilters]) {
        count++
      }
    })
    return count
  }

  return (
    <Box minHeight='80vh'>
      <Formik
        initialValues={INITIAL_FILTER_VALUES}
        onSubmit={values => console.log(values)}
      >
        { ({ values, resetForm }) =>
          <Form>
            <Stack spacing={6}>
              <SearchEngineTitle
                filtersApplied={getModifiedFieldCount(values)}
                resetFilters={resetForm}
              />
              <SimpleGrid columns={[1, 2, 3]} spacing={4}>
                <FormikControl
                  name='economicGroup'
                  control='chakraSelect'
                  label='Economic Group'
                  options={[
                    { value: '1', key: 'Option 1' }
                  ]}
                />
                <FormikControl
                  name='clientName'
                  control='chakraInput'
                  label='Client Name'
                />
                <FormikControl
                  name='contractId'
                  control='chakraInput'
                  label='Contract Id'
                />
                <FormikControl
                  control='chakraDatePicker'
                  name='contractSignDate'
                  label='Contract Sign Date'
                />
                <FormikControl
                  name='contractType'
                  control='chakraSelect'
                  label='Contract Type'
                  options={[
                    { key: 'Syndicated Loan', value: 'Syndicated Loan' },
                    { key: 'Bilateral Loan', value: 'Bilateral Loan' },
                    { key: 'Project Finance', value: 'Project Finance' }
                  ]}
                />
                <FormikControl
                  name='banker'
                  control='chakraSelect'
                  label='Banker / Executive'
                  options={[
                    { value: 'Vivian Yung', key: 'Vivian Yung' },
                    { value: 'John Doe', key: 'John Doe' },
                    { value: 'Patrick Rowlins', key: 'Patrick Rowlins' }
                  ]}
                />
              </SimpleGrid>
              <Box pt={16}>
                <SearchEngineTable filters={values} />
              </Box>
            </Stack>
          </Form>
        }
      </Formik>
    </Box>
  )
}
