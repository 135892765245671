import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid } from '@chakra-ui/react'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type Contract } from '../../../../../types/contract'
import { Item } from './Item'

interface Props {
  contract: Contract
}

export const PaymentStructure = ({ contract }: Props) => {
  const BANKS_OPTIONS = contract.paymentStructures.map(ps => {
    return [
      { left: 'TRANCHE', right: contract.financingStructures.find(fs => fs.id === ps.tranche)?.tranche ?? '' },
      { left: 'PAYMENT TYPE', right: ps.paymentType },
      { left: 'BANK', right: contract.banks.find(bank => bank.id === ps.bank)?.bankName ?? '' },
      { left: 'CURRENCY', right: ps.currency },
      { left: '# OF INSTALLMENTS', right: formatNumber(ps.installments, ps.currency as ICURRENCY) },
      { left: 'TRANCHE VALUE', right: formatNumber(ps.trancheValue, ps.currency as ICURRENCY) }
    ]
  })
  return (
    <AccordionItem >
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Payment Structure
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          { BANKS_OPTIONS.map((bank, index) =>
            <Item
              key={`ps-${index}`}
              info={bank}
              title={`6. Payment Structure ${index + 1}`}
            />
          )}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
