import { format } from 'date-fns'
import { useState, useEffect } from 'react'
import { MyTable } from '../../../../components/table/MyTable'
import { formatNumber } from '../../../../components/utils/currency'
import { type IPayment } from '../../../../types/payment'

interface RowProps extends IPayment {
  amountParsed: string
}

interface Props {
  payments: IPayment[]
}

export const HistoricalPayments = ({ payments }: Props) => {
  const [rows, setRows] = useState<RowProps[]>([])

  useEffect(() => {
    const rows = payments.map((payment) => ({
      ...payment,
      paymentDateParsed: format(new Date(payment.paymentDate), 'dd/MM/yyyy'),
      amountParsed: `$${formatNumber(payment.totalAmount.toFixed(2), payment.currency, true)}`
    }))
    setRows(rows)
  }, [payments])

  return (
    <MyTable
      headers={HEADERS}
      rows={rows}
    />
  )
}

const HEADERS = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Tranche', value: 'trancheName' },
  { label: 'Company name', value: 'clientName' },
  { label: 'PAYMENT DATE', value: 'paymentDateParsed' },
  { label: 'Total amount', value: 'amountParsed' },
  { label: 'Currency', value: 'currency' }
]
