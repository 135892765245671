import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import { Box } from '@chakra-ui/react'
import classes from './Dashboard.module.css'

import Sidebar from '../../layout/sidebar/Sidebar'
import { TopBar } from '../../layout/topbar/TopBar'

const Dashboard = () => {
  const [collapsed, setCollapsed] = useState(false)

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed)
  }

  return (
    <React.Fragment>
      <Box display='flex' flexDirection='row' backgroundColor='#f9fafc' height='100%'>
        <Box backgroundColor='white' position='fixed' zIndex={10}>
          <Sidebar collapsed={collapsed} handleCollapsedChange={handleCollapsedChange} />
        </Box>
        <Box
          className={classes.outlet}
          paddingLeft='1rem'
          paddingRight='1rem'
          ml={{ base: 0, md: collapsed ? '120px' : '310px' }}
          width='100%'
          height='max-content'
          paddingTop='5rem'
          backgroundColor='#f9fafc'
          overflow='auto'
        >
          <TopBar handleCollapsedChange={handleCollapsedChange} collapsed={collapsed} />
          <Outlet />
        </Box>
      </Box>
    </React.Fragment>
  )
}

export default Dashboard
