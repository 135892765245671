import { Box, Button, Stack } from '@chakra-ui/react'
import { useState } from 'react'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { OBLIGATIONS_NOT_TO_DO, OBLIGATIONS_TO_DO } from '../../../../../../components/utils/obligations'
import { scrollToTop } from '../../../../../../components/utils/utils'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { type IObligation } from '../../../../../../types/contract'
import { ObligationItems } from './ObligationItems'

export const Obligations = () => {
  const { contract, prevStep, nextStep, setContract } = useCreateContractContext()
  const [obligationsToDo, setObligationsToDo] = useState<IObligation[]>(contract.obligationsToDo)
  const [obligationsNotToDo, setObligationsNotToDo] = useState<IObligation[]>(contract.obligationsNotToDo)

  const onSubmit = () => {
    setContract(prev => { return { ...prev, obligationsNotToDo, obligationsToDo } })
    nextStep()
    scrollToTop()
  }

  return (
    <Stack spacing={8} mb={12}>
      <MainTitle title='11. Obligations' variant='subsection' styles={{ maxWidth: '100%' }} />
      <ObligationItems
        title='• Obligations to do'
        obligationsChecked={obligationsToDo}
        obligationsList={OBLIGATIONS_TO_DO}
        setter={setObligationsToDo}
      />
      <ObligationItems
        title='• Obligations not to do'
        obligationsChecked={obligationsNotToDo}
        obligationsList={OBLIGATIONS_NOT_TO_DO}
        setter={setObligationsNotToDo}
      />
      <Box width='100%' textAlign='right' mt='8px'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button onClick={onSubmit} colorScheme='teal' ml='12px' fontSize={{ base: 14, lg: 16 }}>
          Next step
        </Button>
      </Box>
    </Stack>
  )
}
