import { SimpleGrid, Stack, Text } from '@chakra-ui/react'
import FormikControl, { type FormikControlProps } from '../../../../../../components/formComponents/FormikControl'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'

const INPUTS = [
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.name',
    label: 'Name'
  },
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.phone',
    label: 'Phone'
  },
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.email',
    label: 'Email'
  },
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.office',
    label: 'Office'
  },
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.notary',
    label: 'Notary'
  },
  {
    control: 'chakraInput',
    name: 'lawyers.externalLawyer.repertoire',
    label: 'Repertoire'
  }
]

export const Lawyers = () => {
  return (
    <Stack spacing={8} mb={12}>
      <MainTitle title='3. Lawyers' variant='subsection' styles={{ maxWidth: '100%' }} />

      <SimpleGrid columns={[1, null, 2]} spacing='16px'>
        <Text pl='4' fontSize={18} fontWeight={600} color='black'>Bank Responsible</Text>
        <div />
        <FormikControl
          control='chakraSelect'
          options={CONTRACT_TYPE_OPTIONS}
          name='lawyers.bankResponsible.name'
          label='Name'
        />
        <div />
        <Text pl='4' pt='2' fontSize={18} fontWeight={600} color='black'>External Lawyer</Text>
        <div />
        { INPUTS.map((input) => <FormikControl key={input.name} {...input as FormikControlProps} />)}
      </SimpleGrid>
    </Stack>
  )
}

const CONTRACT_TYPE_OPTIONS = [
  { key: 'Evelyn Pohl', value: 'Evelyn Pohl' },
  { key: 'Jane Smith', value: 'Jane Smith' },
  { key: 'Adam Rockwood', value: 'Adam Rockwood' },
  { key: 'Charlotte Klein', value: 'Charlotte Klein' }
]
