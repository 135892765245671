import React, { useState } from 'react'
import { hideLoader, showLoader } from '../components/loader/Loader'
import { useChakraToast } from '../hooks/useChakraToast'
import { createAccountWithGoogle, logoutFromGoogleAccount } from '../services/auth/googleAuth'
import { createNormalAccount, passwordReset } from '../services/auth/normalAuth'
import { addUser, getUserByEmail } from '../services/userService'
import { type IUser } from '../types/user'

interface IAuthContext {
  user: IUser
  isLoggedIn: boolean
  login: (user: IUser) => void
  logout: () => void
  createGoogleAccount: () => Promise<boolean>
  handlerCreateNormalAccount: (email: string, password: string) => Promise<boolean>
  handlePasswordRecovery: (email: string) => Promise<boolean>
}

const USER_INITIAL_STATE: IUser = {
  email: '',
  role: 'Bank Executive',
  accountType: 'normal'
}

const AuthContext = React.createContext<IAuthContext>({
  user: USER_INITIAL_STATE,
  isLoggedIn: false,
  login: () => {},
  logout: () => {},
  createGoogleAccount: async () => false,
  handlerCreateNormalAccount: async () => false,
  handlePasswordRecovery: async () => false
})

interface Props {
  children: React.ReactNode
}

export const AuthContextProvider = ({ children }: Props) => {
  const { showToast } = useChakraToast()
  const [user, setUser] = useState<IUser>(
    () => JSON.parse(sessionStorage.getItem('user') ?? JSON.stringify(USER_INITIAL_STATE))
  )

  const userIsLoggedIn = !!user

  const createGoogleAccount = async () => {
    // 1. Get Email from Google
    const googleUser = await createAccountWithGoogle()
    if (!googleUser) return false

    // 2. Check if user exists in DB
    showLoader()
    const userExist = await getUserByEmail(googleUser.email)
    if (Object.keys(userExist.data).length) {
      showToast('error', 'User already exists')
      await logoutFromGoogleAccount()
      hideLoader()
      return false
    }

    const newUser: IUser = {
      email: googleUser?.email ?? '',
      accountType: 'google',
      role: 'Bank Executive'
    }
    await addUser(newUser)
    hideLoader()
    showToast('success', 'Account created successfully')
    return true
  }

  const handlerCreateNormalAccount = async (email: string, password: string) => {
    // 1. Check if user exists in DB
    showLoader()
    const userExist = await getUserByEmail(email)
    if (Object.keys(userExist.data).length) {
      showToast('error', 'User already exists')
      hideLoader()
      return false
    }

    const newUser: IUser = {
      email,
      accountType: 'normal',
      role: 'Bank Executive'
    }
    await createNormalAccount(newUser, password)
    showToast('success', 'Account created successfully')
    hideLoader()
    return true
  }

  const handlePasswordRecovery = async (email: string) => {
    // 1. Check if user exists in DB
    showLoader()
    const userExist = await getUserByEmail(email)
    if (!Object.keys(userExist.data).length) {
      showToast('error', 'User does not exist')
      hideLoader()
      return false
    }
    // 2. Check if user has a normal account
    const user = userExist.data[Object.keys(userExist.data)[0]]
    if (user.accountType !== 'normal') {
      showToast('warning', 'This email is associated with a Google account')
      hideLoader()
      return false
    }
    // 3. Send email with password recovery link
    const resp = await passwordReset(email)
    if (!resp) {
      showToast('error', 'Error sending recovery email')
      hideLoader()
      return false
    }
    showToast('success', 'Email sent successfully, check your inbox')
    hideLoader()
    return true
  }

  const logoutHandler = () => {
    setUser(USER_INITIAL_STATE)
    sessionStorage.removeItem('user')
  }

  const loginHandler = (user: IUser) => {
    setUser(user)
    sessionStorage.setItem('user', JSON.stringify(user))
  }

  const contextValue: IAuthContext = {
    user,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
    createGoogleAccount,
    handlerCreateNormalAccount,
    handlePasswordRecovery
  }

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuthContext = () => {
  return React.useContext(AuthContext)
}

export default AuthContext
