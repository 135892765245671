import { Box, Text } from '@chakra-ui/react'
import { formatNumber, type ICURRENCY } from '../../../../../../../components/utils/currency'
import { type IFinancingStructure } from '../../../../../../../types/contract'

interface Props {
  financingStructure: IFinancingStructure
}

export const FinancingStructureItem = ({ financingStructure }: Props) => {
  const BOX_ONE = [
    { label: 'Tranche', value: financingStructure.tranche },
    { label: 'FINANCING TYPE', value: financingStructure.financingType },
    { label: 'CURRENCY', value: financingStructure.currency },
    { label: 'INTEREST RATE', value: `${financingStructure.rate}%` },
    { label: 'TERM (YEARS)', value: formatNumber(financingStructure.term, financingStructure.currency as ICURRENCY) },
    { label: 'AMOUNT', value: formatNumber(financingStructure.amount, financingStructure.currency as ICURRENCY, true) }
  ]
  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='60%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px'>
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='40%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
