import { Icon } from '@chakra-ui/icons'
import { Box, Divider, Text } from '@chakra-ui/react'
import { MdCheckCircle, MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md'
import { useCreateContractContext } from '../../../../../contexts/CreateContractContext'
import { useResponsive } from '../../../../../hooks/useResponsive'

export const Stepper = () => {
  const { tabs, currentStep } = useCreateContractContext()
  const { isMobile } = useResponsive()

  return (
    <>
    { !isMobile
      ? <Box borderRadius={12} borderWidth={1} color='gray.400' height='fit-content' borderColor='rgba(29, 29, 29, 0.25)'>
        <Text
          textAlign='center'
          px={6}
          minWidth={250}
          pt={6}
          pb={2}
          color='black'
          fontWeight={600}
          fontSize={18}
        >New Contract Creation</Text>
        <Divider color='black' mb='1.5rem' />
        { tabs.map((tab, i) => {
          if (i > currentStep) {
            return (
              <Unchecked key={tab} label={tab}/>
            )
          }
          if (i === currentStep) {
            return (
              <InProgress key={tab} label={tab}/>
            )
          }
          return (
            <Checked key={tab} label={tab}/>
          )
        })
        }
      </Box>
      : <Box borderRadius={12} borderWidth={1} color='gray.400' height='fit-content' borderColor='rgba(29, 29, 29, 0.25)' width='fit-content'>
        <Text
          textAlign='center'
          width='fit-content'
          p='10px 30px'
          color='black'
          fontWeight={600}
          fontSize={16}
        >New Contract Creation</Text>
        <Divider color='black' mb='1.5rem' />
        { tabs.map((tab, i) => {
          if (i === currentStep) {
            return (
              <InProgress key={tab} label={tab}/>
            )
          }
          return null
        })
        }
      </Box>
    }
    </>
  )
}

interface CheckedProps {
  label: string
}

const Unchecked = ({ label }: CheckedProps) => (
  <Box display='flex' alignItems='center' gap={1.5} ml={6} mb={4}>
    <Icon as={MdRadioButtonUnchecked} boxSize={4} color='gray.400' />
    <Text fontSize={16} fontWeight={400}>{label}</Text>
  </Box>
)

const InProgress = ({ label }: CheckedProps) => (
  <Box display='flex' alignItems='center' gap={1.5} ml={6} mb={4} color='teal.500'>
    <Icon as={MdRadioButtonChecked} boxSize={4} color='teal.500' />
    <Text fontSize={{ base: 14, sm: 14, md: 14, lg: 16 }} fontWeight={600}>{label}</Text>
  </Box>
)

const Checked = ({ label }: CheckedProps) => (
  <Box display='flex' alignItems='center' gap={1.5} ml={6} mb={4} color='#41B025'>
    <Icon as={MdCheckCircle} boxSize={4} color='#41B025' />
    <Text fontSize={16} fontWeight={600}>{label}</Text>
  </Box>
)
