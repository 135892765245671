import { Divider } from '@chakra-ui/react'
import qx from '../../assets/images/login/qx.png'
import './LoginLayout.css'

interface LoginLayoutProps {
  children: React.ReactNode
}

export const LoginLayout = ({ children }: LoginLayoutProps) => {
  return (
    <div className='container'>
      <div className='container-left'>
        <div className='container-left__content'>
          <img src='https://firebasestorage.googleapis.com/v0/b/syndicated-loans-prototype.appspot.com/o/static%2Flogo.png?alt=media&token=6d8ca784-f084-462d-899c-867954ec4552' alt='Logo' className='container-left__content-logo'/>
          <div className='container-left__content-disclaimer'>
            <a href='https://www.quantum-x.io/' target='_blank' rel="noreferrer">
              <img src={qx} alt='Quantum-X' className='container-left__content-disclaimer-logo' />
            </a>
            <Divider w='75%' mt='-1.5' mb='2'/>
            <p className='container-left__content-disclaimer-label'>© {new Date().getFullYear()} QuantumX </p>
            <p className='container-left__content-disclaimer-label'>All rights reserved</p>
          </div>
        </div>
      </div>
      <div className='container-right'>
        { children }
      </div>
    </div>
  )
}
