import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import FormikControl from '../../../../../../../components/formComponents/FormikControl'
import { type IBank } from '../../../../../../../types/contract'
import * as yup from 'yup'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (bank: IBank) => void
}

export const BanksModal = ({ isOpen, onClose, onAdd }: Props) => {
  const handleSubmit = (values: IBank, formikHelpers: FormikHelpers<IBank>) => {
    onAdd(values)
    formikHelpers.resetForm()
    onClose()
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent m='1.5rem' p='0.5rem' maxWidth='360px'>
            <ModalCloseButton />
            <ModalHeader fontWeight={700} fontSize={18} color='gray.800'>
              Bank information
            </ModalHeader>
            <ModalBody>
              <Stack spacing={1}>
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  name={'bankName'}
                  placeholder='Bank name'
                  label='Bank name'
                />
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  suffix='%'
                  name={'loanDistribution'}
                  placeholder='Enter participation percentage'
                  label='Enter participation percentage'
                />
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Role'
                  name={'role'}
                  placeholder='Role'
                />
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Position'
                  name={'position'}
                  placeholder='Position'
                />
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Responsible name'
                  name={'responsibleName'}
                  placeholder='Responsible name'
                />
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Phone'
                  name={'phone'}
                  placeholder='Phone'
                />
                <FormikControl
                  size='sm'
                  control='chakraInput'
                  label='Email'
                  name={'email'}
                  placeholder='Email'
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                type='submit'
                fontSize={{ base: 14, lg: 16 }}
                mr={3}
              >
                Add Bank
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: IBank = {
  id: '',
  bankName: '',
  loanDistribution: 0,
  role: '',
  position: '',
  responsibleName: '',
  phone: '',
  email: ''
}

const validationSchema = yup.object({
  bankName: yup.string().required('Required'),
  loanDistribution: yup.number().min(0.001, 'Must be greater than 0%').max(100, 'Must be less than 100%').required('Required'),
  role: yup.string().required('Required'),
  position: yup.string().required('Required'),
  responsibleName: yup.string().required('Required'),
  phone: yup.string().matches(/^[0-9\s]+$/, 'Invalid phone format').required('Required'),
  email: yup.string().email('Invalid email format').required('Required')
})
