import { useCallback } from 'react'
import { hideLoader, showLoader } from '../components/loader/Loader'
import { addPayment, getAllPayments, getPaymentByTrancheId } from '../services/paymentService'
import { type Contract } from '../types/contract'
import { type IPaymentRegister, type IPayment } from '../types/payment'
import { useChakraToast } from './useChakraToast'
import { useContract } from './useContract'

export const usePayment = () => {
  const { showToast } = useChakraToast()
  const { getContracts } = useContract()

  const createPayment = async (payment: IPayment) => {
    showLoader()
    await addPayment(payment)
    hideLoader()
    showToast('success', 'Payment created successfully')
  }

  const getPayments = useCallback(async () => {
    showLoader()
    const resp = await Promise.all([
      getContracts(),
      getAllPayments()
    ])

    const contracts = resp[0]
    const resPayments = resp[1]
    hideLoader()
    const payments: IPaymentRegister[] = []
    for (const key in resPayments) {
      const contract: Contract | undefined = contracts.find(({ contractId }) => contractId === resPayments[key].contractId)
      const trancheName = contract?.financingStructures.find(({ id }) => id === resPayments[key].trancheId)?.tranche
      payments.push({
        _id: key,
        clientName: contract?.client.name,
        trancheName,
        ...resPayments[key]
      })
    }
    return payments
  }, [])

  const getPaymentsByTranche = useCallback(async (trancheId: string) => {
    showLoader()
    const response: any = await getPaymentByTrancheId(trancheId)
    hideLoader()

    const payments: IPayment[] = []
    for (const key in response.data) {
      payments.push({
        _id: key,
        ...response.data[key]
      })
    }
    return payments.sort((a: IPayment, b: IPayment) => a.paymentTrancheIndex - b.paymentTrancheIndex)
  }, [])

  return {
    createPayment,
    getPayments,
    getPaymentsByTranche
  }
}
