import { Routes, Route, Navigate } from 'react-router-dom'
import { CreateAccount } from '../pages/CreateAccount/CreateAccount'

import Home from '../pages/Home'
import { RecoverPassword } from '../pages/RecoverPassword/RecoverPassword'
import { useAuthContext } from '../store/auth-context'
import AgentBankRoutes from './AgentBankRoutes'
import BankExecutiveRoutes from './BankExecutiveRoutes'

const AppRoutes = () => {
  const { user } = useAuthContext()

  const AuthRoutes = user.role === 'Agent Bank' ? AgentBankRoutes : BankExecutiveRoutes

  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='create-account' element={<CreateAccount />} />
      <Route path='recover-password' element={<RecoverPassword />} />
      <Route path='auth/*' element={ <AuthRoutes />} />

      <Route path='*' element={<Navigate to='/' replace />} />
    </Routes>
  )
}

export default AppRoutes
