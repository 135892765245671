import httpService from './httpService'

const contractsEndpoint = '/logs.json'

export function addLog (log) {
  return httpService.post(contractsEndpoint, log, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export async function getAllLogs () {
  const response = await httpService.get(contractsEndpoint)
  return response.data
}

export function getLogById (id) {
  return httpService.get(`${contractsEndpoint}/${id}`)
}

export function updateLog (id, updatedFields) {
  const updateEndpoint = `logs/${id}.json`

  return httpService.patch(updateEndpoint, updatedFields, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}
