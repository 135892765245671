import { useResponsive } from '../../hooks/useResponsive'
import { DesktopSidebar } from './DesktopSidebar'
import { MobileSidebar } from './MobileSidebar'
import {
  MdFeed,
  MdSearch,
  MdInventory2,
  MdPayments
  /* MdSupervisedUserCircle */
} from 'react-icons/md'
import { useAuthContext } from '../../store/auth-context'
export interface SidebarProps {
  collapsed: boolean
  handleCollapsedChange: () => void
  routes: Array<{
    label: string
    path: string
    Icon: any
    subMenu?: Array<{
      label: string
      path: string
    }>
  }>
}

const BANK_EXECUTIVE_PATHS = [
  {
    label: 'Contracts',
    path: 'contracts',
    Icon: MdFeed
  },
  {
    label: 'Payments',
    path: 'payments',
    Icon: MdPayments
  },
  {
    label: 'Logs',
    path: 'logs',
    Icon: MdInventory2
  },
  {
    label: 'Search Engine',
    path: 'search',
    Icon: MdSearch
  }
]

const AGENT_BANKS_PATHS = [
  {
    label: 'Contracts',
    path: 'contracts',
    Icon: MdFeed
  },
  /* {
    label: 'Agent Bank Executives',
    path: 'executives',
    Icon: MdSupervisedUserCircle
  }, */
  {
    label: 'Search Engine',
    path: 'search',
    Icon: MdSearch
  }
]

const Sidebar = (props: SidebarProps) => {
  const { user } = useAuthContext()
  const { isMobile } = useResponsive()
  const routes = user.role === 'Agent Bank' ? AGENT_BANKS_PATHS : BANK_EXECUTIVE_PATHS
  return (
    <>
    {
      isMobile
        ? <MobileSidebar {...{ ...props, routes }} />
        : <DesktopSidebar {...{ ...props, routes }} />
    }
    </>
  )
}

export default Sidebar
