import { useNavigate } from 'react-router-dom'
import { hideLoader, showLoader } from '../components/loader/Loader'
import { loginWithGoogle, logoutFromGoogleAccount } from '../services/auth/googleAuth'
import { normalAccountLogin } from '../services/auth/normalAuth'
import { getUserByEmail } from '../services/userService'
import { useAuthContext } from '../store/auth-context'
import { useChakraToast } from './useChakraToast'

export const useLogin = () => {
  const { login } = useAuthContext()
  const { showToast } = useChakraToast()
  const navigate = useNavigate()

  const redirectToDashboard = () => {
    navigate('/auth/contracts', { replace: true })
  }

  const googleLogin = async () => {
    // 1. Login with google
    const googleUser = await loginWithGoogle()
    if (!googleUser) return
    // 2. Check if user exist in database
    showLoader()
    const userExist = await getUserByEmail(googleUser?.email)
    if (!Object.keys(userExist.data).length) {
      showToast('error', 'User not found')
      await logoutFromGoogleAccount()
      hideLoader()
      return
    }
    // 3. Check if the user is a google account
    const user = userExist.data[Object.keys(userExist.data)[0]]
    if (user.accountType !== 'google') {
      showToast('warning', 'This account is not registered as a google account')
      await logoutFromGoogleAccount()
      hideLoader()
      return
    }
    hideLoader()
    login(user)
    redirectToDashboard()
  }

  const googleLogout = async () => {
    showLoader()
    await logoutFromGoogleAccount()
    hideLoader()
  }

  const handlerNormalAccountLogin = async (email: string, password: string) => {
    // 1. Check if user exist in database
    showLoader()
    const userExist = await getUserByEmail(email)
    if (!Object.keys(userExist.data).length) {
      showToast('error', 'User not found')
      hideLoader()
      return
    }
    // 2. Check if the user is a normal account
    const user = userExist.data[Object.keys(userExist.data)[0]]
    if (user.accountType !== 'normal') {
      showToast('warning', 'This account is registered with another login method')
      hideLoader()
      return
    }
    // 3. Login with normal account
    const resp = await normalAccountLogin(email, password)
    if (typeof resp === 'string') {
      showToast('error', resp)
      hideLoader()
      return
    }
    // 4. Check if email is verified
    if (!resp?.emailVerified) {
      showToast('warning', 'Please verify your email')
      hideLoader()
      return
    }
    login(user)
    hideLoader()
    navigate('/auth/contracts', { replace: true })
  }

  return {
    googleLogin,
    googleLogout,
    handlerNormalAccountLogin
  }
}
