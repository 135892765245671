import { useResponsive } from '../../hooks/useResponsive'

interface Props {
  title: string
  variant?: 'section' | 'subsection'
  styles?: any
}

export const MainTitle = ({ title, variant = 'section', styles }: Props) => {
  const { isMobile } = useResponsive()
  return (
    <div
      style={{
        fontSize: fontSize[variant],
        fontWeight: 600,
        color: '#000000',
        paddingBottom: 16,
        borderBottom: '1px solid #000000',
        maxWidth: isMobile ? 'min-content' : 'fit-content',
        paddingLeft: 8,
        paddingRight: 8,
        ...styles
      }}
    >{ title }</div>
  )
}

const fontSize = {
  section: '32px',
  subsection: '20px'
}
