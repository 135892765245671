
import { type IPayment } from '../../types/payment'

import { useState, useEffect } from 'react'
import { usePayment } from '../../hooks/usePayment'

interface Props {
  trancheId: string
  children: (args: { payments: IPayment[] }) => JSX.Element
}

export const AmortizationHOC = ({ trancheId, children }: Props) => {
  const [payments, setPayments] = useState<IPayment[]>([])
  const { getPaymentsByTranche } = usePayment()

  useEffect(() => {
    async function init () {
      const payments = await getPaymentsByTranche(trancheId)
      setPayments(payments)
    }
    void init()
    return () => {
      setPayments([])
    }
  }, [trancheId, getPaymentsByTranche])

  return (
    <>
    { children({ payments }) }
    </>
  )
}
