import { Box, Button, IconButton } from '@chakra-ui/react'
import { FaBars } from 'react-icons/fa'
import { FiPower, FiBell, FiMail } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { useResponsive } from '../../hooks/useResponsive'
import { useAuthContext } from '../../store/auth-context'
import './TopBar.css'

interface Props {
  handleCollapsedChange: () => void
  collapsed: boolean
}

const bgColor = '#f9fafc'

export const TopBar = ({ handleCollapsedChange, collapsed }: Props) => {
  const navigate = useNavigate()
  const { logout } = useAuthContext()
  const { isMobile } = useResponsive()

  const sizeProps = isMobile
    ? {
        width: '95%',
        ml: 0
      }
    : {
        width: `calc(100% - ${collapsed ? '80px' : '270px'})`,
        ml: '-55px'
      }

  const logoutButtonHandler = () => {
    logout()
    navigate('/', { replace: true })
  }

  return (
    <Box
      className='container-topbar'
      position='fixed'
      top={0}
      padding={2}
      display='flex'
      justifyContent='space-between'
      zIndex={1}
      backgroundColor={bgColor}
      {...sizeProps}
    >
      { !isMobile
        ? <div />
        : <IconButton
        onClick={handleCollapsedChange}
        icon={<FaBars />}
        backgroundColor={bgColor}
        aria-label='menu'
      />

      }
      <Box display='flex' gap={2}>
        <Button backgroundColor={bgColor}>
          <FiBell />
        </Button>
        <Button backgroundColor={bgColor}>
          <FiMail />
        </Button>
        <IconButton
          aria-label='logout'
          onClick={logoutButtonHandler}
          icon={<FiPower />}
          backgroundColor={bgColor}
        />
      </Box>
    </Box>)
}
