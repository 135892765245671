import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { useContract } from '../../../../../../hooks/useContract'

export const FinishPage = () => {
  const { contract, prevStep } = useCreateContractContext()
  const { createContract } = useContract()
  const navigate = useNavigate()

  const onSubmit = async () => {
    await createContract(contract)
    navigate('/auth/contracts')
  }

  return (
    <Stack spacing={8} mb={12}>
      <MainTitle title='Confirmation' variant='subsection' styles={{ maxWidth: '100%' }} />
      <Text fontSize={14} color='#1D1D1D'>
        Before finalizing the contract, we kindly ask you to ensure the accuracy of the information provided. Please take a moment to review the details you entered in the form:
      </Text>
      <Text fontSize={14} color='#1D1D1D'>
        Loan ID: {contract.contractId} <br />
        Contract Type: {contract.contractType} <br />
        Contract Purpose: {contract.financingPurpose} <br />
        Contract Start Date: {format(new Date(contract.contractSignDate), 'dd/MM/yyyy')} <br />
        Contract End Date: {format(new Date(contract.contractExpirationDate), 'dd/MM/yyyy')} <br />
        Parties Involved: {contract.banks.map(bank => bank.bankName).join(', ')} <br />
      </Text>
      <Text fontSize={14} color='#1D1D1D'>
        If you find any discrepancies or if any modifications need to be made, please go back to the corresponding step to make the necessary changes.
      </Text>
      <Text fontSize={14} color='#1D1D1D'>
        To confirm the creation of the contract, please click on the &quot;Confirm&quot; button below.
      </Text>
      <Box width='100%' textAlign='right' mt='8px'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button colorScheme='teal' ml='12px' onClick={onSubmit} fontSize={{ base: 14, lg: 16 }}>
          Confirm
        </Button>
      </Box>
    </Stack>
  )
}
