import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid } from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import * as yup from 'yup'
import FormikControl from '../../../../../../components/formComponents/FormikControl'
import { CURRENCY_OPTIONS } from '../../../../../../components/utils/currency'
import { type IGuarantees } from '../../../../../../types/contract'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (guarantees: IGuarantees) => void
}

export const GuaranteesModal = ({ isOpen, onClose, onAdd }: Props) => {
  const handleSubmit = (values: IGuarantees, formikHelpers: FormikHelpers<IGuarantees>) => {
    onAdd(values)
    formikHelpers.resetForm()
    onClose()
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm, values }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent minW={[null, '4xl']} p='0.5rem' m='1.5rem' >
            <ModalCloseButton />
            <ModalHeader fontWeight={700} fontSize={18} color='gray.800'>
              Guarantee information
            </ModalHeader>
            <ModalBody>
              <SimpleGrid columns={[1, null, 2]} spacing='6px' >
                <FormikControl size='sm' control='chakraInput' name='clientName' label='Client Name' />
                <FormikControl size='sm' control='chakraInput' name='notary' label='Notary' />
                <FormikControl
                  size='sm'
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='controlCard'
                  label='Guarantee Control Card Number'
                />
                <FormikControl size='sm' control='chakraInput' name='repertoire' label='Repertoire' />
                <FormikControl size='sm'
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='commercialValue'
                  label='Commercial Value'
                />
                <FormikControl size='sm'
                  control='chakraSelect'
                  name='type'
                  label='Guarantee Type'
                  options={[
                    { key: 'Other', value: 'Other' }
                  ]}
                />
                <FormikControl size='sm'
                  control='chakraSelect'
                  name='currency'
                  label='Currency'
                  options={CURRENCY_OPTIONS}
                />
                <FormikControl size='sm' control='chakraInput' name='lawyer' label='Lawyer in Charge of Guarantee Control' />
                <FormikControl size='sm'
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='appraisal'
                  label='Appraisal'
                />
                <FormikControl size='sm'
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='liquidationValue'
                  label='Guarantee Liquidation Value'
                />
                <FormikControl size='sm' control='chakraDatePicker' name='constitutionDate' label='Guarantee Constitution Date' />
                <FormikControl size='sm' control='chakraDatePicker' name='expirationDate' label='Guarantee Expiration Date' />
              </SimpleGrid>
              <FormikControl size='sm' control='chakraTextarea' name='note' label='Notes' height='10px' placeholder='Write here'/>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                type='submit'
                fontSize={{ base: 14, lg: 16 }}
                mr={3}
              >
                Add Guarantee
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: IGuarantees = {
  clientName: '',
  notary: '',
  controlCard: 0,
  repertoire: '',
  commercialValue: 0,
  type: '',
  currency: '',
  lawyer: '',
  appraisal: 0,
  liquidationValue: 0,
  constitutionDate: new Date(),
  expirationDate: new Date(),
  note: ''
}

const validationSchema = yup.object({
  clientName: yup.string().required('Required'),
  notary: yup.string().required('Required'),
  controlCard: yup.number().required('Required'),
  repertoire: yup.string().required('Required'),
  commercialValue: yup.number().required('Required'),
  type: yup.string().required('Required'),
  currency: yup.string().required('Required'),
  lawyer: yup.string().required('Required'),
  appraisal: yup.number().required('Required'),
  constitutionDate: yup.date().typeError('Invalid date format').max(yup.ref('expirationDate'), 'Guarantee Constitution Date must be before of Guarantee Expiration Date').required('Required'),
  expirationDate: yup.date().typeError('Invalid date format').required('Required'),
  liquidationValue: yup.number().required('Required'),
  note: yup.string()
})
