import { Box, Button, Stack, Text, Tooltip } from '@chakra-ui/react'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { MyTable, type MyTableHeadersProps } from '../../../../components/table/MyTable'
import { useEffect, useState, memo } from 'react'
import MyIconButton from '../../../../components/MyIconButton/MyIconButton'
import { MdAdd, MdBarChart, MdReadMore } from 'react-icons/md'
import { useContract } from '../../../../hooks/useContract'
import { type Contract } from '../../../../types/contract'
import { type ICURRENCY, formatNumber } from '../../../../components/utils/currency'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../../../hooks/useDialog'
import { MyLoanDocuments } from '../../../../components/documents/MyLoanDocuments'
import { AmortizationTrancheTabs } from '../../../../components/amortization/AmortizationTrancheTabs'

const headers: MyTableHeadersProps[] = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Total amount', value: 'totalAmountParsed', isNumeric: true },
  { label: 'Currency', value: 'currency' },
  { label: 'AMORTIZATION SCHEDULE (THOUSANDS)', value: 'amortizationButton', thAlign: 'center' },
  { label: 'VIEW DETAILS', value: 'detailsButton', thAlign: 'center' },
  { label: 'DOCUMENTATION', value: 'documentationButton', thAlign: 'center' },
  { label: 'ADMIN APPROVAL', value: 'statusComponent' }
]

export const Contracts = () => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const [rows, setRows] = useState<RowsProps[]>([])
  const {
    closeDialog: closeDocuments,
    data: documentsData,
    isOpen: isOpenDocuments,
    openDialog: openDocuments
  } = useDialog<Contract>()
  const {
    closeDialog: closeAmortization,
    data: dataAmortization,
    isOpen: isOpenAmortization,
    openDialog: openAmortization
  } = useDialog<Contract>()
  const navigate = useNavigate()
  const { getContracts } = useContract()

  useEffect(() => {
    const getContractsData = async () => {
      const contracts = await getContracts()
      setContracts(contracts)
    }
    void getContractsData()

    return () => { setContracts([]) }
  }, [getContracts])

  useEffect(() => {
    setRows(contracts.map(contract => {
      return {
        ...contract,
        totalAmountParsed: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true),
        amortizationButton:
          <MyIconButton
            aria-label='Amortization schedule'
            icon={MdBarChart}
            onClick={() => {
              if (dataAmortization?._id === contract._id) {
                closeAmortization()
              } else {
                closeDocuments()
                openAmortization(contract)
              }
            }}
          />,
        detailsButton:
          <MyIconButton
            aria-label='View details'
            boxSize={8}
            icon={MdAdd}
            colorScheme='teal'
            variant='outline'
            onClick={() => navigate(`/auth/contracts/${contract.contractId}`, { state: { contract } })}
          />,
        documentationButton:
          <MyIconButton
            aria-label='Documentation'
            boxSize={8}
            icon={MdReadMore}
            colorScheme='teal'
            variant='ghost'
            onClick={() => {
              if (documentsData?._id === contract._id) {
                closeDocuments()
              } else {
                closeAmortization()
                openDocuments(contract)
              }
            }}
          />,
        statusComponent: <StatusComponent contract={contract} />
      }
    }))
    return () => { setRows([]) }
  }, [
    contracts,
    isOpenDocuments,
    documentsData,
    isOpenAmortization,
    dataAmortization
  ])

  return (
    <>
      <Stack spacing='12' mb='4rem'>
        <Stack gap={{ base: 6, lg: 0 }}>
          <MainTitle title='Registered Contracts' styles={{ marginLeft: 16 }} />
          <Box width='100%' textAlign='right'>
            <Button
              colorScheme='teal'
              onClick={() => navigate('/auth/newContract')}
              fontSize={{ base: 14, lg: 16 }}
            >
              Add new contract
            </Button>
          </Box>
        </Stack>
        <MyTable headers={headers} rows={rows}/>
        { isOpenDocuments
          ? <Stack spacing={2}>
            <MainTitle variant='subsection' title='Documentation' />
            <MyLoanDocuments
              isPreview
              files={documentsData?.documents}
            />
          </Stack>
          : null
        }
        { isOpenAmortization
          ? <Stack spacing={6}>
            <MainTitle variant='subsection' title='Amortization Schedule' />
            <AmortizationTrancheTabs contract={dataAmortization} />
          </Stack>
          : null
        }
      </Stack>
    </>
  )
}

const COLOR = {
  APPROVED: '#41B025',
  PENDING: '#C09E25',
  REJECTED: '#801616'
}

const STATUS_TEXT = {
  APPROVED: 'Approved',
  PENDING: 'Pending',
  REJECTED: 'Rejected'
}

const StatusComponent = memo(function StatusComponent ({ contract }: { contract: Contract }) {
  if (contract.status !== 'REJECTED') {
    return (
      <Text
        color={COLOR[contract.status]}
        fontSize={14}
        fontWeight={500}
        textTransform='capitalize'
        maxWidth='fit-content'
      >{STATUS_TEXT[contract.status]}</Text>
    )
  }
  return (
    <Tooltip
      hasArrow
      label={contract.rejectedNote}
      placement='top'
      borderRadius='12px'
      fontSize='12px'
    >
      <Text
        color={COLOR[contract.status]}
        maxWidth='fit-content'
        fontSize={14}
        fontWeight={500}
        _hover={{ cursor: 'help' }}
        textTransform='capitalize'
      >{STATUS_TEXT[contract.status]}</Text>
    </Tooltip>
  )
})

interface RowsProps extends Contract {
  totalAmountParsed: string
  amortizationButton: JSX.Element
  detailsButton: JSX.Element
  documentationButton: JSX.Element
}
