import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, ListItem, SimpleGrid, UnorderedList } from '@chakra-ui/react'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { type IObligation } from '../../../../../types/contract'

interface Props {
  obligationsToDo: IObligation[]
  obligationsNotToDo: IObligation[]
}

export const Obligations = ({ obligationsNotToDo = [], obligationsToDo = [] }: Props) => {
  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Obligations
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          <Box
            bg='#E2E8F0'
            borderRadius={12}
            minW='300px'
            p='26px'
            h='fit-content'
            boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
          >
            <MainTitle title='11. Obligations to do' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
            <UnorderedList fontWeight={600} color='#1A202C' >
            { obligationsToDo.map((item, index) =>
              <ListItem key={index}>{item.label}</ListItem>
            )}
            </UnorderedList>
          </Box>
          <Box
            bg='#E2E8F0'
            borderRadius={12}
            minW='300px'
            p='26px'
            h='fit-content'
            boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
          >
            <MainTitle title='11. Obligations not to do' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
            <UnorderedList fontWeight={600} color='#1A202C'>
            { obligationsNotToDo.map((item, index) =>
              <ListItem key={index}>{item.label}</ListItem>
            )}
            </UnorderedList>
          </Box>
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
