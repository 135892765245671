import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { SingleDatepicker } from 'chakra-dayzed-datepicker'
import { type FieldProps, getIn, FastField } from 'formik'

export interface ChakraDatePickerProps {
  label: string
  name: string
  [propName: string]: any
}

export const ChakraDatePicker = (props: ChakraDatePickerProps) => {
  const { label, name, ...rest } = props
  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} htmlFor={name} color='gray.700' fontWeight={600}>{label}</FormLabel>
          <SingleDatepicker
            id={name}
            onDateChange={date => form.setFieldValue(name, String(date))}
            date={new Date(field.value)}
            propsConfigs={{
              dayOfMonthBtnProps: {
                defaultBtnProps: {
                  _hover: {
                    background: 'blue.400'
                  }
                },
                selectedBtnProps: {
                  background: 'blue.300'
                },
                todayBtnProps: {
                  background: 'blue.100'
                }
              }
            }}
            {...rest}
          />
          <FormErrorMessage>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  )
}
