import { getAuth, signInWithPopup, signOut } from '@firebase/auth'
import { googleAuthProvider } from '../../firebase'

const auth = getAuth()

export const createAccountWithGoogle = async () => {
  try {
    const { user } = await signInWithPopup(auth, googleAuthProvider)
    return user
  } catch (error) {
    console.log(error)
  }
}

export const loginWithGoogle = async () => {
  try {
    const resp = await signInWithPopup(auth, googleAuthProvider)
    return resp.user
  } catch (error) {
    console.log(error)
  }
}

export const logoutFromGoogleAccount = async () => {
  await signOut(auth)
    .then(() => {
      // Desconexión exitosa de la cuenta de Google
      console.log('Cierre de sesión de cuenta de Google exitoso')
    })
    .catch((error) => {
      // Error durante el proceso de cierre de sesión
      console.error('Error al cerrar sesión de cuenta de Google:', error)
    })
}
