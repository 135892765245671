import { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import MyIconButton from '../../../../components/MyIconButton/MyIconButton'
import { MyTable, type MyTableHeadersProps } from '../../../../components/table/MyTable'
import { formatNumber, type ICURRENCY } from '../../../../components/utils/currency'
import { type Contract } from '../../../../types/contract'

interface Props {
  contracts: Contract[]
}

interface RowsProps extends Contract {
  detailsButton: JSX.Element
  totalAmountParsed: string
  borrower: string
}

export const ApprovedContracts = ({ contracts }: Props) => {
  const [rows, setRows] = useState<RowsProps[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    setRows(contracts.map(contract => {
      return {
        ...contract,
        totalAmountParsed: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true),
        borrower: contract.client.name,
        detailsButton:
          <MyIconButton
            aria-label='View details'
            boxSize={8}
            icon={MdAdd}
            colorScheme='teal'
            variant='outline'
            onClick={() => navigate(`/auth/contracts/${contract.contractId}`, { state: { contract } })}
          />
      }
    }))
  }, [contracts])

  return (
    <MyTable
      rows={rows}
      headers={headers}
    />
  )
}

const headers: MyTableHeadersProps[] = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'BORROWER', value: 'borrower' },
  { label: 'Total amount', value: 'totalAmountParsed' },
  { label: 'Currency', value: 'currency' },
  { label: 'VIEW DETAILS', value: 'detailsButton', thAlign: 'center' }
]
