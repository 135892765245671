import { FastField, type FieldProps } from 'formik'
import {
  RadioGroup,
  Stack,
  Radio
} from '@chakra-ui/react'

export interface ChakraRadioProps {
  label: string
  name: string
  options: Array<{ label: string, value: string }>
  [propName: string]: any
}

function ChakraRadio (props: ChakraRadioProps) {
  const { label, name, options, ...rest } = props
  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => (
        <RadioGroup
          defaultValue={field.value}
          onChange={value => form.setFieldValue(name, value)}
          value={field.value}
        >
          <Stack spacing={4} direction='row' {...rest} >
            { options.map(({ label, value }) =>
              <Radio value={value} key={value}>
                {label}
              </Radio>
            )}
          </Stack>
        </RadioGroup>
      )}
    </FastField>
  )
}

export default ChakraRadio
