import { Icon } from '@chakra-ui/icons'
import { MenuItem, SubMenu } from 'react-pro-sidebar'
import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import { useResponsive } from '../../hooks/useResponsive'

import './Sidebar.css'

export const SidebarItem = ({ label, path, ...rest }) => {
  const resolved = useResolvedPath(path)
  const { isMobile } = useResponsive()
  const match = useMatch({ path: resolved.pathname, end: true })
  const icon = rest?.Icon || null

  if (rest?.subMenu?.length) {
    return (
      <SubMenu key={label} defaultOpen={isMobile} title={label} icon={<Icon as={icon} boxSize={6}/>}>
        {rest.subMenu.map((item, index) =>
          <SidebarItem {...item} key={index}/>
        )}
      </SubMenu>
    )
  }

  return (
    <MenuItem
      key={label}
      className={match ? 'sidebar-item__active' : ''}
      icon={icon ? <Icon as={icon} boxSize={6} /> : null}
    >
      {label}
      <Link to={path} />
    </MenuItem>
  )
}
