import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid } from '@chakra-ui/react'
import { format } from 'date-fns'
import { type ICovenant } from '../../../../../types/contract'
import { Item } from './Item'

interface Props {
  covenants: ICovenant[]
}

export const Covenants = ({ covenants }: Props) => {
  const COVENANTS_OPTIONS = covenants.map(covenant => {
    return [
      { left: 'COVENANT TYPE', right: covenant.type },
      { left: 'COVENANT', right: covenant.covenant },
      { left: 'FIRST MEASUREMENT DATE', right: format(new Date(covenant.firstMeasurementDate), 'dd/MM/yyyy') },
      { left: 'COVENANT PERIOD', right: covenant.period }
    ]
  })
  return (
    <AccordionItem >
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Covenants
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          { COVENANTS_OPTIONS.map((covenant, index) =>
            <Item
              key={`covenant-${index}`}
              info={covenant}
              title={`9. Covenant ${index + 1}`}
            />
          )}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
