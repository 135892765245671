import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmPaymentModal = ({ isOpen, onClose, onSubmit }: Props) => {
  return (
    <Modal
        isOpen={isOpen}
        onClose={onClose}
        isCentered
        motionPreset='slideInBottom'
      >
        <ModalOverlay
          bg='blackAlpha.500'
          backdropFilter='blur(4px)'
        />
        <ModalContent p='12px'>
          <ModalCloseButton />
          <ModalHeader mt='20px' fontWeight={700} fontSize={20} color='gray.800' textAlign='center'>
            Add New Payment
          </ModalHeader>
          <ModalBody>
            <Text>Please confirm that you want to register this payment</Text>
          </ModalBody>
          <ModalFooter display='flex' justifyContent='center'>
            <Button
              colorScheme='teal'
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
  )
}
