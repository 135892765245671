import { Box, Button, Stack } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { useContract } from '../../../../hooks/useContract'
import { useLogs } from '../../../../hooks/useLogs'
import { type Contract } from '../../../../types/contract'
import { type ILog } from '../../../../types/log'
import { LogForm } from './LogForm'

export const CreateLog = () => {
  const [contracts, setContracts] = useState<Contract[]>([])
  const { getContracts } = useContract()
  const { createLog } = useLogs()
  const navigation = useNavigate()

  useEffect(() => {
    async function fetchContracts () {
      const contracts = await getContracts()
      setContracts(contracts)
    }
    void fetchContracts()

    return () => setContracts([])
  }, [getContracts])

  const returnToLogs = () => {
    navigation('/auth/logs')
  }

  const onSubmit = async (log: ILog) => {
    await createLog(log)
    returnToLogs()
  }

  return (
    <Stack spacing={12} mb='16'>
      <Button
        colorScheme='blackAlpha'
        width='fit-content'
        onClick={returnToLogs}
        fontSize={{ base: 14, lg: 16 }}
      >
        Back to Logs
      </Button>
      <Box display='flex' flexDirection='column' gap='1.5rem'>
        <MainTitle
          title={'1. Entry new log'}
          variant='subsection'
          styles={{ minWidth: '100%' }}
        />
        <LogForm
          key={contracts.length}
          onSubmit={onSubmit}
          contracts={contracts}
        />
      </Box>
    </Stack>
  )
}
