import httpService from './httpService'

const contractsEndpoint = '/payments.json'

export function addPayment (payment) {
  return httpService.post(contractsEndpoint, payment, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export async function getAllPayments () {
  const response = await httpService.get(contractsEndpoint)
  return response.data
}

export function getPaymentById (id) {
  return httpService.get(`${contractsEndpoint}/${id}`)
}

export function updatePayment (id, updatedFields) {
  const updateEndpoint = `payments/${id}.json`

  return httpService.patch(updateEndpoint, updatedFields, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export function getPaymentByTrancheId (trancheId) {
  return httpService.get(`${contractsEndpoint}?orderBy="trancheId"&equalTo="${trancheId}"&print=pretty`)
}
