import { Route, Routes } from 'react-router-dom'
import Dashboard from '../components/dashboard/Dashboard'
import { SEContractDetails } from '../components/searchEngine/SEContractDetails'
import { ContractDetails } from '../pages/Dashboard/AgentBank/Contracts/ContractDetails/ContractDetails'
import { RegisteredContracts } from '../pages/Dashboard/AgentBank/Contracts/RegisteredContracts'
import { SearchEnginAdmin } from '../pages/Dashboard/AgentBank/SearchEngine/SearchEnginAdmin'

const AgentBankRoutes = () => {
  return (
    <Routes>
      <Route path='' element={<Dashboard />}>
        <Route path='contracts' element={<RegisteredContracts />} />
        <Route path='contracts/:id' element={<ContractDetails />} />
        <Route path='executives' element={<h1>Agent Bank Executives Page</h1>} />
        <Route path='search' element={<SearchEnginAdmin />} />
        <Route path='search/:id' element={<SEContractDetails />} />

      </Route>
    </Routes>
  )
}

export default AgentBankRoutes
