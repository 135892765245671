import { useToast } from '@chakra-ui/react'

const title = {
  success: 'Success',
  warning: 'Warning',
  info: 'Info',
  error: 'Error'
}
type types = 'success' | 'warning' | 'info' | 'error'

export const useChakraToast = () => {
  const toast = useToast()

  const showToast = (status: types, description: string) => {
    toast({
      title: title[status],
      description,
      status,
      duration: 3000,
      isClosable: true,
      position: 'top-right',
      variant: 'top-accent'
    })
  }

  return { showToast }
}
