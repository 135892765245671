import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { memo } from 'react'
import FormikControl from '../../../../components/formComponents/FormikControl'
import { type Contract } from '../../../../types/contract'

interface Props {
  isOpen: boolean
  contract: Contract
  onClose: () => void
  onValidateContract: (contractId: string, isValid: boolean, rejectedNote: string) => Promise<void>
}

const INITIAL_VALUES = {
  isValid: 'true',
  rejectedNote: ''
}

export const ValidateContract = memo(function ValidateContract ({ isOpen, onClose, contract, onValidateContract }: Props) {
  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={async ({ isValid, rejectedNote }, formikActions) => {
        await onValidateContract(contract?._id ?? '', isValid === 'true', rejectedNote)
        formikActions.resetForm()
        onClose()
      }}
      validationSchema={validationSchema}
    >
    { ({ resetForm }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent p='0.5rem' m='1.5rem'>
            <ModalCloseButton />
            <ModalHeader mt='20px' fontWeight={700} fontSize={20} color='gray.800' textAlign='center'>
              Validate contract
            </ModalHeader>
            <ModalBody>
              <Stack spacing={6}>
                <Stack>
                  <Text fontSize={{ base: 14, lg: 16 }}>Would you like to approve this contract?</Text>
                  <FormikControl
                    control='chakraRadio'
                    name='isValid'
                    label=''
                    options={[
                      { label: 'Yes', value: 'true' },
                      { label: 'No', value: 'false' }
                    ]}
                  />
                </Stack>
                <Stack>
                  <Text fontSize={{ base: 14, lg: 16 }}>What are the adjustments that must be done?</Text>
                  <FormikControl
                    control='chakraTextarea'
                    name='rejectedNote'
                    height='10px'
                    placeholder='Write here'
                    label=''
                  />
                </Stack>
              </Stack>
            </ModalBody>
            <ModalFooter display='flex' justifyContent='center'>
              <Button
                colorScheme='teal'
                type='submit'
                fontSize={{ base: 14, lg: 16 }}
              >
                Confirm
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
})

const validationSchema = yup.object({
  isValid: yup.string().required('Required'),
  rejectedNote: yup.string().when('isValid', {
    is: 'false',
    then: yup.string().required('Required')
  })
})
