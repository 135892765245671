import PropTypes from 'prop-types'
import { Field, getIn, type FieldProps } from 'formik'
import {
  Textarea,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'

export interface ChakraTextareaProps {
  label: string
  name: string
  height: string
  [propName: string]: any
}

function ChakraTextarea (props: ChakraTextareaProps) {
  const { label, name, height, ...rest } = props
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} htmlFor={name} color='gray.700' fontWeight={600}>{label}</FormLabel>
          <Textarea id={name} height={height} {...rest} {...field} />
          <FormErrorMessage>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}

ChakraTextarea.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
}

export default ChakraTextarea
