import { Icon } from '@chakra-ui/icons'
import { Box, Text } from '@chakra-ui/react'
import { MdClose, MdFilterList } from 'react-icons/md'

interface Props {
  filtersApplied: number
  resetFilters: () => void
}

export const SearchEngineTitle = ({ filtersApplied, resetFilters }: Props) => {
  return (
    <Box display='flex' alignItems='center' gap={6} borderBottom='1px solid #1D1D1D80' pb='16px'>
      <Icon as={MdFilterList} boxSize={6} />
      <Text fontWeight={600} fontSize={{ base: 20, lg: 18 }} color='black'>Filter by</Text>
      { filtersApplied > 0
        ? <>
          <Text fontWeight={400} fontSize={{ base: 14, lg: 16 }} color='black'>({filtersApplied} applied)</Text>
          <Box
            display='flex'
            gap={2}
            alignItems='center'
            justifyContent='center'
            borderRadius={6}
            backgroundColor='#3182CE'
            py='2px'
            px='8px'
          >
            <Text fontWeight={500} fontSize={12} color='white'>Clear al filters</Text>
            <Icon
              as={MdClose}
              color='white'
              opacity='50%'
              _hover={{ opacity: '100%' }}
              cursor='pointer'
              onClick={resetFilters}
            />
          </Box>
        </>
        : null
      }
    </Box>
  )
}
