import PropTypes from 'prop-types'
import { FastField, getIn, type FieldProps } from 'formik'
import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'

export interface ChakraInputProps {
  label: string
  name: string
  [propName: string]: any
}

function ChakraInput (props: ChakraInputProps) {
  const { label, name, ...rest } = props
  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} fontWeight={600} htmlFor={name} color='gray.700'>{label}</FormLabel>
          <Input id={name} {...rest} {...field} />
          <FormErrorMessage textTransform='capitalize'>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  )
}

ChakraInput.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default ChakraInput
