import { Box, Button, Stack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { CreateContractProvider } from '../../../../../contexts/CreateContractContext'
import { useResponsive } from '../../../../../hooks/useResponsive'
import { BanksFinances } from './BanksFinances/BanksFinances'
import { BasicInfo } from './BasicInfo/BasicInfo'
import { Covenants } from './Covenants/Covenants'
import { Documents } from './Documents/Documents'
import { Fees } from './Fees/Fees'
import { FinishPage } from './FinishPage/FinishPage'
import { Guarantees } from './Guarantees/Guarantees'
import { Insurance } from './Insurance/Insurance'
import { Obligations } from './Obligations/Obligations'
import { PaymentStructures } from './PaymentStructures/PaymentStructures'
import { Stepper } from './Stepper'
import { StepperItem } from './StepperItem'

export const ContractCreation = () => {
  const navigate = useNavigate()
  const { isMobile } = useResponsive()

  return (
    <CreateContractProvider>
      <Stack spacing='8' mb='4rem' mt='1rem'>
        <Button
          colorScheme='blackAlpha'
          maxWidth='min-content'
          onClick={() => navigate('/auth/contracts')}
          fontSize={{ base: 14, lg: 16 }}
        >
          Back to Contracts
        </Button>
        <Box
          display='flex'
          gap={6}
          flexDirection={isMobile ? 'column' : 'row'}
        >
          <Stepper />
          { FORMS.map((Form, index) =>
            <StepperItem key={index} step={index}>
              <Form />
            </StepperItem>
          )}
        </Box>
      </Stack>
    </CreateContractProvider>
  )
}

const FORMS = [
  BasicInfo,
  BanksFinances,
  PaymentStructures,
  Guarantees,
  Insurance,
  Covenants,
  Fees,
  Obligations,
  Documents,
  FinishPage
]
