import { useCallback } from 'react'
import { hideLoader, showLoader } from '../components/loader/Loader'
import { addLog, getAllLogs } from '../services/logService'
import { type ILog } from '../types/log'
import { useChakraToast } from './useChakraToast'

export const useLogs = () => {
  const { showToast } = useChakraToast()

  const createLog = async (log: ILog) => {
    showLoader()
    await addLog(log)
    hideLoader()
    showToast('success', 'Contract created successfully')
  }

  const getLogs = useCallback(async () => {
    showLoader()
    const response = await getAllLogs()
    hideLoader()
    const logs: ILog[] = []
    for (const key in response) {
      logs.push({
        _id: key,
        ...response[key]
      })
    }
    return logs
  }, [])

  return {
    createLog,
    getLogs
  }
}
