import { GoogleAuthProvider } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import { getStorage } from 'firebase/storage'

const firebaseConfig = {
  apiKey: 'AIzaSyCMGqC4iofjVus8hYOfNs0T2OYh32T3uv8',
  authDomain: 'syndicated-loans-prototype.firebaseapp.com',
  databaseURL: 'https://syndicated-loans-prototype-default-rtdb.firebaseio.com',
  projectId: 'syndicated-loans-prototype',
  storageBucket: 'syndicated-loans-prototype.appspot.com',
  messagingSenderId: '176727321579',
  appId: '1:176727321579:web:7c13f18b212439184287ae'
}

initializeApp(firebaseConfig)

const storage = getStorage()
const googleAuthProvider = new GoogleAuthProvider()

export {
  storage,
  googleAuthProvider
}
