import httpService from './httpService'

const contractsEndpoint = '/contracts.json'

export function addContract (contract) {
  return httpService.post(contractsEndpoint, contract, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}

export async function getAllContracts () {
  const response = await httpService.get(contractsEndpoint)
  return response.data
}

export function getContractById (id) {
  return httpService.get(`${contractsEndpoint}/${id}`)
}

export function updateContract (id, updatedFields) {
  const updateEndpoint = `contracts/${id}.json`

  return httpService.patch(updateEndpoint, updatedFields, {
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    }
  })
}
