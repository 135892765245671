import { Button, Stack, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { type Location, useLocation, useNavigate } from 'react-router-dom'
import { AmortizationHOC } from '../../../../components/amortization/AmortizationHOC'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { type ICURRENCY } from '../../../../components/utils/currency'
import { useDialog } from '../../../../hooks/useDialog'
import { usePayment } from '../../../../hooks/usePayment'
import { type Contract } from '../../../../types/contract'
import { type IPayment } from '../../../../types/payment'
import { AmortizationTableToPay } from './AmortizationTableToPay'
import { ConfirmPaymentModal } from './ConfirmPaymentModal'
import { StaticAmortizationTableToPay } from './StaticAmortizationTableToPay'

interface LocationProps {
  state: {
    contract: Contract
  }
}

export const PayAmortization = () => {
  const { closeDialog, data, isOpen, openDialog } = useDialog<IPayment>()
  const location: Location = useLocation()
  const { createPayment } = usePayment()
  const navigation = useNavigate()
  const { contract } = (location as LocationProps).state

  const backToPayments = () => {
    navigation('/auth/payments')
  }

  const onSubmit = async () => {
    if (data) {
      await createPayment(data)
      closeDialog()
      backToPayments()
    }
  }

  return (
    <Stack spacing={12 } mb='16'>
      <ConfirmPaymentModal
        isOpen={isOpen}
        onClose={closeDialog}
        onSubmit={onSubmit}
      />
      <Button
        colorScheme='blackAlpha'
        width='fit-content'
        onClick={backToPayments}
        fontSize={{ base: 14, lg: 16 }}
      >
        Back to Payments
      </Button>
      <MainTitle
        title={`Add New Payment - Contract ID #${contract.contractId}`}
        variant='subsection'
        styles={{ minWidth: '100%' }}
      />
      <Tabs>
        <TabList>
          { contract.financingStructures.map((tranche, index) =>
            <Tab
              key={tranche.id}
              fontSize={16}
              fontWeight={600}
              color='#B3B3B3'
              _selected={{ color: '#38B6FF', borderBottom: '2px solid #38B6FF' }}
              _hover={{ color: '#83D0FD', borderBottom: '2px solid #83D0FD' }}
            >
              Tranche #{index + 1}
            </Tab>
          )}
        </TabList>
        <TabPanels>
          { contract.financingStructures.map((tranche, index) =>
            <TabPanel key={index}>
              <AmortizationHOC trancheId={tranche.id}>
                { ({ payments }) =>
                  <>{tranche.isConstantPayments === 'no'
                    ? <StaticAmortizationTableToPay
                      payments={payments}
                      tranche={tranche}
                      onPay={async (
                        paymentTrancheIndex: number,
                        paymentDate: Date,
                        totalAmount: number
                      ) => {
                        const payment: IPayment = {
                          contractId: contract.contractId,
                          trancheId: tranche.id,
                          currency: tranche.currency as ICURRENCY,
                          paymentTrancheIndex,
                          paymentDate,
                          totalAmount
                        }
                        openDialog(payment)
                      }}
                      currency={tranche.currency as ICURRENCY}
                    />
                    : <AmortizationTableToPay
                      payments={payments}
                      amount={tranche.amount}
                      rate={tranche.rate / 100}
                      startDate={new Date(tranche.firstPaymentDate)}
                      years={tranche.term}
                      onPay={async (
                        paymentTrancheIndex: number,
                        paymentDate: Date,
                        totalAmount: number
                      ) => {
                        const payment: IPayment = {
                          contractId: contract.contractId,
                          trancheId: tranche.id,
                          currency: tranche.currency as ICURRENCY,
                          paymentTrancheIndex,
                          paymentDate,
                          totalAmount
                        }
                        openDialog(payment)
                      }}
                      currency={tranche.currency as ICURRENCY}
                    />
                  }
                  </>
                }
              </AmortizationHOC>

            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </Stack>
  )
}
