import { useResponsive } from '../../hooks/useResponsive'
import { DesktopTable } from './DesktopTable'

export interface MyTableHeadersProps {
  label: string
  value: string
  isNumeric?: boolean
  thAlign?: 'left' | 'right' | 'center'
}

export interface MyTableProps {
  headers: MyTableHeadersProps[]
  rows: any[]
}

export const MyTable = (props: MyTableProps) => {
  const { isMobile } = useResponsive()

  if (isMobile) return <DesktopTable {...props} />
  return <DesktopTable {...props} />
}
