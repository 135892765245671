import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack } from '@chakra-ui/react'
import { Form, Formik, type FormikHelpers } from 'formik'
import FormikControl from '../../../../../../components/formComponents/FormikControl'
import { type ICovenant } from '../../../../../../types/contract'
import * as yup from 'yup'

interface Props {
  isOpen: boolean
  onClose: () => void
  onAdd: (covenant: ICovenant) => void
}

export const CovenantModal = ({ isOpen, onClose, onAdd }: Props) => {
  const handleSubmit = (values: ICovenant, formikHelpers: FormikHelpers<ICovenant>) => {
    onAdd(values)
    formikHelpers.resetForm()
    onClose()
  }

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
    { ({ resetForm }) =>
      <Modal
        isOpen={isOpen}
        onClose={() => {
          onClose()
          resetForm()
        }}
        isCentered
        motionPreset='slideInBottom'
      >
        <Form>
          <ModalOverlay
            bg='blackAlpha.500'
            backdropFilter='blur(4px)'
          />
          <ModalContent maxWidth='360px' p='0.5rem' m='1.5rem' >
            <ModalCloseButton />
            <ModalHeader fontWeight={700} fontSize={18} color='gray.800'>
              Covenant
            </ModalHeader>
            <ModalBody>
              <Stack spacing={2}>
                <FormikControl
                  control='chakraSelect'
                  name='type'
                  label='Covenant Type'
                  options={[
                    { key: 'Financial', value: 'Financial' },
                    { key: 'Other', value: 'Other' }
                  ]}
                />
                <FormikControl
                  control='chakraSelect'
                  name='covenant'
                  label='Covenant'
                  options={[
                    { key: 'DSCR', value: 'DSCR' },
                    { key: 'Other', value: 'Other' }
                  ]}
                />
                <FormikControl
                  control='chakraDatePicker'
                  name='firstMeasurementDate'
                  label='First Measurement Date'
                />
                <FormikControl
                  control='chakraSelect'
                  name='period'
                  label='Covenant Period'
                  options={[
                    { key: 'Other', value: 'Other' },
                    { key: 'Monthly', value: 'Monthly' }
                  ]}
                />
              </Stack>
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='teal'
                type='submit'
                fontSize={{ base: 14, lg: 16 }}
                mr={3}
              >
                Add Covenant
              </Button>
            </ModalFooter>
          </ModalContent>
        </Form>
      </Modal>
    }
    </Formik>
  )
}

const INITIAL_VALUES: ICovenant = {
  id: '',
  covenant: '',
  firstMeasurementDate: new Date(),
  period: '',
  type: ''
}

const validationSchema = yup.object({
  covenant: yup.string().required('Required'),
  firstMeasurementDate: yup.date().typeError('Invalid Format Date').required('Required'),
  period: yup.string().required('Required'),
  type: yup.string().required('Required')
})
