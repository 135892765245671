import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { NumericFormat } from 'react-number-format'
import { Field, getIn, type FieldProps } from 'formik'
import { currencies, type ICURRENCY } from '../../utils/currency'

export interface ChakraCurrencyInputProps {
  name: string
  label: string
  currency: ICURRENCY
  [propName: string]: any
}

export const ChakraCurrencyInput = ({ name, label, currency = 'USD', ...rest }: ChakraCurrencyInputProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} fontWeight={600} htmlFor={name} color='gray.700'>{label}</FormLabel>
            <NumericFormat
              {...currencies[currency]}
              prefix='$'
              decimalScale={currencies[currency]?.decimalScale ?? 2}
              value={field.value}
              onValueChange={(values) => {
                const { floatValue } = values
                form.setFieldValue(name, floatValue)
              }}
              allowNegative={false}
              customInput={Input}
              fixedDecimalScale
              {...rest}
            />
          <FormErrorMessage>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
