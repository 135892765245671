import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type IGuarantees } from '../../../../../types/contract'
import { Item } from './Item'

export const Guarantees = ({ guarantees }: { guarantees: IGuarantees[] }) => {
  const GUARANTEES = guarantees.map(guarantees => [
    { left: 'CLIENT NAME', right: guarantees.clientName },
    { left: 'NOTARY', right: guarantees.notary },
    { left: 'GUARANTEE CONTROL CARD NUMBER', right: formatNumber(guarantees.controlCard, guarantees.currency as ICURRENCY) },
    { left: 'REPERTOIRE', right: guarantees.repertoire },
    { left: 'COMMERCIAL VALUE', right: formatNumber(guarantees.commercialValue, guarantees.currency as ICURRENCY) },
    { left: 'GUARANTEE TYPE', right: guarantees.type },
    { left: 'CURRENCY', right: guarantees.currency },
    { left: 'LAWYER IN CHARGE OF GUARANTEE CONTROL', right: guarantees.lawyer },
    { left: 'APPRAISAL', right: formatNumber(guarantees.appraisal, guarantees.currency as ICURRENCY) },
    { left: 'GUARANTEE LIQUIDATION VALUE', right: formatNumber(guarantees.liquidationValue, guarantees.currency as ICURRENCY) },
    { left: 'GUARANTEE CONSTITUTION DATE', right: format(new Date(guarantees.constitutionDate), 'dd/MM/yyyy') },
    { left: 'GUARANTEE EXPIRATION DATE', right: format(new Date(guarantees.expirationDate), 'dd/MM/yyyy') }
  ])

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Guarantees
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid spacing={5}>
          { GUARANTEES.map((item, index) =>
            <SimpleGrid key={index} minChildWidth='300px' spacing={5}>
              <Item info={item} title={`7. Guarantees ${index + 1}`}/>
              <Box
                bg='#E2E8F0'
                borderRadius={12}
                minWidth='45%'
                p='26px'
                h='fit-content'
                boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
              >
                <MainTitle title={`7. Notes ${index + 1}`} variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
                <Text>{guarantees[index].note}</Text>
              </Box>
            </SimpleGrid>
          )}
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
