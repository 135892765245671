import { useMediaQuery } from '@chakra-ui/react'

const MEDIA_QUERY = {
  mobile: '(max-width: 767px)',
  tablet: '(min-width: 768px) and (max-width: 1023px)',
  desktop: '(min-width: 1024px)'
}

export const useResponsive = () => {
  const isMobile = useMediaQuery(MEDIA_QUERY.mobile)
  const isTablet = useMediaQuery(MEDIA_QUERY.tablet)
  const isDesktop = useMediaQuery(MEDIA_QUERY.desktop)

  return {
    isMobile: isMobile[0],
    isTablet: isTablet[0],
    isDesktop: isDesktop[0]
  }
}
