const LOADER_BOX_ID = 'loader-box'

export const showLoader = () => {
  document.body.classList.add('loader-on')
  if (document.getElementById(LOADER_BOX_ID)) document.getElementById(LOADER_BOX_ID)!.classList.add('on')
}

export const hideLoader = () => {
  document.body.classList.remove('loader-on')
  if (document.getElementById(LOADER_BOX_ID)) document.getElementById(LOADER_BOX_ID)!.classList.remove('on')
}

export default function Loader () {
  return (
    <div id="loader-box" className=''>
        <div className="loader">Loading...</div>
    </div>
  )
}
