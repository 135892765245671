import { Stack } from '@chakra-ui/react'
import { type document, type documents, type FOLDERS } from '../../types/documents'
import { MainTitle } from '../mainTitle/MainTitle'
import { DocumentList } from './DocumentList'
import { PreviewDesign } from './PreviewDesign'
import { UploadButton } from './UploadButton'

interface Props {
  isPreview?: boolean
  files: documents
  onLoadFile?: (file: document, folder: FOLDERS) => void
  onDelete?: (file: document, folder: FOLDERS) => void
}

export const MyLoanDocuments = ({ isPreview = false, onLoadFile, files, onDelete }: Props) => {
  const SECTIONS: FOLDERS[] = ['BUSINESS', 'FINANCIAL', 'LEGAL', 'OTHERS']

  return (
    <Stack spacing='12'>
      { isPreview
        ? <PreviewDesign
          files={files}
          sections={SECTIONS}
        />
        : SECTIONS.map(section =>
          <Stack key={section} spacing='8'>
            <MainTitle title={section} styles={{ color: '#3D4D6A', fontSize: 16, maxWidth: 170 }} />
            <UploadButton folder={section} onLoadFile={onLoadFile} />
            <DocumentList
              documents={files?.[section] ?? []}
              onDelete={onDelete}
              folder={section}
              isPreview={isPreview}
            />
          </Stack>
        )
      }
    </Stack>
  )
}
