import { Text } from '@chakra-ui/react'

interface Props {
  date: Date
  havePaid: boolean
}

export const AmortizationRowStatus = ({ date, havePaid }: Props) => {
  let status: 'Pending' | 'Paid' | 'Late' = 'Pending'

  if (havePaid) {
    status = 'Paid'
  } else if (date < new Date()) {
    status = 'Late'
  }

  return (
    <Text fontSize={14} fontWeight={600} color={COLOR[status]}>{ status }</Text>
  )
}

const COLOR = {
  Pending: '#C09E25',
  Paid: '#41B025',
  Late: '#801616'
}
