import { Accordion, Button, Stack } from '@chakra-ui/react'
import { type Location, useLocation, useNavigate } from 'react-router-dom'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { type Contract } from '../../../../../types/contract'
import { Banks } from './Banks'
import { BasicInfo } from './BasicInfo'
import { Covenants } from './Covenants'
import { Fees } from './Fees'
import { Guarantees } from './Guarantees'
import { Insurance } from './Insurance'
import { Obligations } from './Obligations'
import { PaymentStructure } from './PaymentStructure'
import { Tranches } from './Tranches'

interface LocationProps {
  state: {
    contract: Contract
  }
}

export const ContractDetail = () => {
  const location: Location = useLocation()
  const navigation = useNavigate()

  const { contract } = (location as LocationProps).state

  return (
    <Stack spacing={12 } mb='16'>
      <Button
        colorScheme='blackAlpha'
        width='fit-content'
        onClick={() => navigation('/auth/contracts')}
        fontSize={{ base: 14, lg: 16 }}
      >
        Back to Registered Contracts
      </Button>
      <MainTitle
        title={`Details - Contract ID #${contract.contractId}`}
        variant='subsection'
        styles={{ minWidth: '100%' }}
      />
      <Accordion allowMultiple color='#2D3748' fontSize={14}>
        <BasicInfo contract={contract} />
        <Banks banks={contract.banks} />
        <Tranches financingStructures={contract.financingStructures}/>
        <PaymentStructure contract={contract} />
        <Guarantees guarantees={contract.guaranteesDetails} />
        <Insurance insurance={contract.insurance} />
        <Covenants covenants={contract.covenantsList} />
        <Fees fees={contract.fees} />
        <Obligations
          obligationsNotToDo={contract.obligationsNotToDo}
          obligationsToDo={contract.obligationsToDo}
        />
      </Accordion>
    </Stack>
  )
}
