import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { ipmt, ppmt } from 'financial'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { formatNumber, type ICURRENCY } from '../utils/currency'
import { usePagination } from '../../hooks/usePagination'
import { AmortizationRowStatus } from './AmortizationRowStatus'
import { type IPayment } from '../../types/payment'
import { useResponsive } from '../../hooks/useResponsive'

interface Props {
  rate: number
  amount: number
  startDate: Date
  years: number
  currency: ICURRENCY
  payments: IPayment[]
}

interface AmortizationData {
  paymentDate: Date
  startingBalance: number
  endingBalance: number
  payment: number
  principal: number
  index: number
  paid: boolean
}

const Amortization = ({ amount, rate, startDate, years, currency, payments }: Props) => {
  const [data, setData] = useState<AmortizationData[]>([])

  useEffect(() => {
    updateAmortization()
  }, [])

  const updateAmortization = () => {
    const paymentsPerYear = 12
    const amortization: AmortizationData[] = []
    let balance = amount
    let paid = false
    for (let i = 0; i < years * paymentsPerYear; i++) {
      const interestPayment = ipmt(rate / paymentsPerYear, i + 1, years * paymentsPerYear, amount, 0)
      let principal = ppmt(rate / paymentsPerYear, i + 1, years * paymentsPerYear, amount, 0)
      let totalPayment = 0
      if (balance === 0) {
        paid = true
      }

      if (balance + principal <= 0) {
        principal = balance * -1.0
        totalPayment = interestPayment + principal
        balance = 0
      } else {
        totalPayment = interestPayment + principal
        balance += principal
      }
      const paymentDate = i === 0 ? startDate : new Date(startDate.getFullYear(), startDate.getMonth() + i + 1, 0)
      amortization.push({
        paymentDate,
        startingBalance: balance + Math.abs(principal),
        payment: totalPayment,
        principal,
        endingBalance: balance,
        paid,
        index: i
      })
    }
    setData(amortization)
  }
  return (
    <TablePaginated rows={data} currency={currency} payments={payments} />
  )
}

interface TableProps {
  currency: ICURRENCY
  rows: AmortizationData[]
  payments: IPayment[]
}

const TablePaginated = ({ rows, currency, payments }: TableProps) => {
  const { MyPagination, filterData } = usePagination(rows)
  const { isMobile } = useResponsive()

  return (
    <Stack spacing={3}>
      <TableContainer style={{ marginTop: '16px' }}>
        <Table variant='striped' size={isMobile ? 'sm' : 'md'} >
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Payment Date</Th>
              <Th isNumeric>Beginning Balance</Th>
              <Th isNumeric>Payment</Th>
              <Th isNumeric>Principal</Th>
              <Th isNumeric>Ending Balance</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filterData.map((row: AmortizationData, index: number) => (
              <Tr key={index} style={{ opacity: row.paid ? 0.5 : 1.0 }} fontSize={14} >
                <Td>{row.index + 1}</Td>
                <Td>{format(row.paymentDate, 'dd/MM/yyyy')}</Td>
                <Td isNumeric>{formatNumber(row.startingBalance.toFixed(2), currency, true)}</Td>
                <Td isNumeric>{formatNumber(Math.abs(row.payment).toFixed(2), currency, true)}</Td >
                <Td isNumeric>{formatNumber(Math.abs(row.principal).toFixed(2), currency, true)}</Td>
                <Td isNumeric>{formatNumber(row.endingBalance.toFixed(2), currency, true)}</Td>
                <Td>
                  <AmortizationRowStatus
                    date={new Date(row.paymentDate)}
                    havePaid={Boolean(payments?.[row.index])}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <MyPagination />
    </Stack>
  )
}

export default Amortization
