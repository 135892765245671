import { Box, Stack, Text } from '@chakra-ui/react'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'

interface IProps {
  info: Array<{ left: string, right: string }>
  title: string
}

export const Item = ({ info, title }: IProps) => {
  return (
    <Box
      bg='#E2E8F0'
      borderRadius={12}
      minWidth='300px'
      p='26px'
      h='fit-content'
      boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
    >
      <MainTitle title={title} variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
      <Stack spacing={1}>
        { info.map(({ left, right }) =>
          <Box key={left} display='flex' gap='8px' fontSize={14}>
            <Text width='50%' fontWeight={700}>{left}</Text>
            <Text width='50%'>{right}</Text>
          </Box>
        )}
      </Stack>
    </Box>
  )
}
