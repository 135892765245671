import { Box, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { type ICovenant } from '../../../../../../types/contract'

interface Props {
  covenant: ICovenant
}

export const CovenantItem = ({ covenant }: Props) => {
  const BOX_ONE = [
    { label: 'COVENANT TYPE', value: covenant.type },
    { label: 'COVENANT', value: covenant.covenant },
    { label: 'FIRST MEASUREMENT DATE', value: format(new Date(covenant.firstMeasurementDate), 'dd/MM/yyyy') },
    { label: 'COVENANT PERIOD', value: covenant.period }
  ]
  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='70%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px' >
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='30%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
