import PropTypes from 'prop-types'
import { FastField, getIn, type FieldProps } from 'formik'
import {
  Select,
  FormControl,
  FormLabel,
  FormErrorMessage
} from '@chakra-ui/react'

export interface ChakraSelectProps {
  label: string
  name: string
  options: Array<{ key: string, value: string }>
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  [propName: string]: any
}

function ChakraSelect (props: ChakraSelectProps) {
  const { label, name, options, handleChange, ...rest } = props
  return (
    <FastField name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} fontWeight={600} color='gray.700' htmlFor={name}>{label}</FormLabel>
          <Select
            id={name}
            {...rest}
            {...field}
            onChange={(e) => {
              handleChange(e)
              field.onChange(e)
            }}
            placeholder='Please Select'
          >
            {options.map((option) => {
              return (
                <option key={option.value} value={option.value}>
                  {option.key}
                </option>
              )
            })}
          </Select>
          <FormErrorMessage>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </FastField>
  )
}
ChakraSelect.defaultProps = {
  handleChange: () => {}
}

ChakraSelect.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChange: PropTypes.func
}

export default ChakraSelect
