import { Box, Button, Stack } from '@chakra-ui/react'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { MyTable, type MyTableHeadersProps } from '../../../../components/table/MyTable'
import { useEffect, useState } from 'react'
import MyIconButton from '../../../../components/MyIconButton/MyIconButton'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useDialog } from '../../../../hooks/useDialog'
import { type ILog } from '../../../../types/log'
import { useLogs } from '../../../../hooks/useLogs'
import { format } from 'date-fns'
import { LogDetails } from './LogDetails'

const headers: MyTableHeadersProps[] = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Event type', value: 'event' },
  { label: 'Entry date', value: 'parsedDate' },
  { label: 'Executive', value: 'banker' },
  { label: 'Description', value: 'descriptionButton', thAlign: 'center' }
]

export const Logs = () => {
  const [logs, setLogs] = useState<ILog[]>([])
  const [rows, setRows] = useState<RowsProps[]>([])
  const { closeDialog, data, isOpen, openDialog } = useDialog<ILog>()
  const navigate = useNavigate()
  const { getLogs } = useLogs()

  useEffect(() => {
    const getLogsData = async () => {
      const logs = await getLogs()
      setLogs(logs)
    }
    void getLogsData()

    return () => { setLogs([]) }
  }, [getLogs])

  useEffect(() => {
    setRows(logs.map(log => {
      return {
        ...log,
        parsedDate: format(new Date(log.entryDate), 'dd/MM/yyyy'),
        descriptionButton:
          <MyIconButton
            aria-label='View details'
            boxSize={8}
            icon={MdAdd}
            colorScheme='teal'
            variant='outline'
            onClick={() => openDialog(log)}
          />
      }
    }))
    return () => { setRows([]) }
  }, [logs])

  return (
    <>
      <LogDetails
        isOpen={isOpen}
        onClose={closeDialog}
        log={data}
      />
      <Stack spacing='12' mb='4rem'>
        <Stack gap={{ base: 6, lg: 0 }}>
          <MainTitle title='Logs' styles={{ marginLeft: 16 }} />
          <Box width='100%' textAlign='right'>
            <Button
              colorScheme='teal'
              onClick={() => navigate('/auth/logs/create')}
              fontSize={{ base: 14, lg: 16 }}
            >
              Add new log
            </Button>
          </Box>
        </Stack>
        <MyTable headers={headers} rows={rows}/>
      </Stack>
    </>
  )
}

interface RowsProps extends ILog {
  parsedDate: string
  descriptionButton: JSX.Element
}
