import { Form, Formik } from 'formik'
import { LoginLayout } from '../../layout/login/LoginLayout'
import * as Yup from 'yup'
import { AbsoluteCenter, Box, Button, Divider, Stack, Text } from '@chakra-ui/react'
import FormikControl from '../../components/formComponents/FormikControl'
import { Icon } from '@chakra-ui/icons'
import { FcGoogle } from 'react-icons/fc'
import { useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../store/auth-context'

export const CreateAccount = () => {
  const { createGoogleAccount, handlerCreateNormalAccount } = useAuthContext()
  const navigate = useNavigate()

  const onCreateGoogleAccount = async () => {
    const isCreated = await createGoogleAccount()
    if (isCreated) {
      navigate('/', { replace: true })
    }
  }

  const onCreateNormalAccount = async (values: typeof INITIAL_VALUES) => {
    const isCreated = await handlerCreateNormalAccount(values.email, values.password)
    if (isCreated) {
      navigate('/', { replace: true })
    }
  }

  return (
    <LoginLayout>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onCreateNormalAccount}
        validationSchema={validationSchema}
      >
      { () =>
        <Form style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Stack w='md' spacing='12' p={4}>
            <Text fontSize={24} fontWeight={500} color='#3D4D6A' textAlign='center'>
              Create your account
            </Text>
            <Text fontSize={16} color='#3D4D6A' textAlign='center'>
              Please enter your email and password
            </Text>
            <Stack spacing='6'>
              <FormikControl
                control='chakraInput'
                name='email'
                label='Email'
                placeholder='Enter email'
              />
              <FormikControl
                control='chakraPasswordInput'
                name='password'
                label='Password'
                placeholder='Enter password'
              />
              <FormikControl
                control='chakraPasswordInput'
                name='repeatPassword'
                label='Confirm password'
                placeholder='Confirm password'
              />
              <Button type='submit' colorScheme='teal'>
                Create Account
              </Button>
              <Box position='relative' paddingY='5' color='#3D4D6A'>
                <Divider />
                <AbsoluteCenter bg='white' px='4' fontSize={14}>
                  or
                </AbsoluteCenter>
              </Box>
              <Button
                colorScheme='teal'
                variant='outline'
                rightIcon={<Icon as={FcGoogle} boxSize={5} />}
                onClick={onCreateGoogleAccount}
              >
                Create account with Google
              </Button>
              <Box textAlign='center' fontSize={14} color='#3D4D6A'>
                <Text display='inline-block'>Already have an account? &nbsp;</Text>
                <Text
                  display='inline-block'
                  color='#38B6FF'
                  _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={() => navigate('/')}
                > Click here</Text>
              </Box>
            </Stack>
          </Stack>
        </Form>
      }
      </Formik>
    </LoginLayout>
  )
}

const INITIAL_VALUES = {
  email: '',
  password: '',
  repeatPassword: ''
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required'),
  password: Yup
    .string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(/[A-Z]/, 'Password must have at least one uppercase letter')
    .matches(/[a-z]/, 'Password must have at least one lowercase letter')
    .matches(/\d/, 'Password must have at least one number')
    .matches(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/, 'Password must have at least one special symbol')
    .required('Password is required'),
  repeatPassword: Yup.string().oneOf([Yup.ref('password'), ''], 'Passwords must match').required('Password is required')
})
