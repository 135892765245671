import { hideLoader, showLoader } from '../components/loader/Loader'
import { addContract, getAllContracts, updateContract } from '../services/contractService'
import { type CONTRACT_STATUS, type Contract } from '../types/contract'
import { useChakraToast } from './useChakraToast'
import { useCallback } from 'react'
import { type IFilters } from '../components/searchEngine/SearchEngine'

export const useContract = () => {
  const { showToast } = useChakraToast()

  const createContract = async (contract: Contract) => {
    showLoader()
    await addContract(contract)
    hideLoader()
    showToast('success', 'Contract created successfully')
  }

  const getContracts = useCallback(async () => {
    showLoader()
    const response = await getAllContracts()
    hideLoader()
    const contracts: Contract[] = []
    for (const key in response) {
      contracts.push({
        _id: key,
        ...response[key]
      })
    }
    return contracts
  }, [])

  const getContractsDividedByStatus = useCallback(async (): Promise<Record<CONTRACT_STATUS, Contract[]>> => {
    const contracts: Record<CONTRACT_STATUS, Contract[]> = {
      APPROVED: [],
      PENDING: [],
      REJECTED: []
    }
    showLoader()
    const response = await getAllContracts()
    hideLoader()
    for (const key in response) {
      const contract: Contract = {
        _id: key,
        ...response[key]
      }
      contracts[contract.status].push(contract)
    }

    return contracts
  }, [])

  const handleValidateContract = async (
    contractId: string,
    isValid: boolean,
    rejectedNote: string
  ) => {
    showLoader()
    const newFields = {
      status: isValid ? 'APPROVED' : 'REJECTED',
      rejectedNote
    }
    const resp = await updateContract(contractId, newFields)
    if (resp.status === 200) {
      showToast('success', `Contract ${isValid ? 'approved' : 'rejected'}  successfully`)
    }
    hideLoader()
  }

  const updateContractValues = async (contractId: string, newValues: any) => {
    showLoader()
    await updateContract(contractId, newValues)
    hideLoader()
  }

  const getContractByFilters = async (filters: IFilters) => {
    const contracts = await getContracts()
    return contracts.filter(contract => {
      const { contractId, contractType, contractSignDate, client } = contract
      const { banker, name, economicGroup } = client
      let isValidContractId = true
      let isValidContractType = true
      let isValidContractSignDate = true
      let isValidBanker = true
      let isValidName = true
      let isValidEconomicGroup = true

      if (filters.economicGroup.length) isValidEconomicGroup = economicGroup.toLocaleLowerCase().includes(filters.economicGroup.toLocaleLowerCase())
      if (filters.banker.length) isValidBanker = banker.toLocaleLowerCase().includes(filters.banker.toLocaleLowerCase())
      if (filters.contractId.length) isValidContractId = contractId.toLocaleLowerCase().includes(filters.contractId.toLocaleLowerCase())
      if (filters.contractType.length) isValidContractType = contractType.toLocaleLowerCase().includes(filters.contractType.toLocaleLowerCase())
      if (filters.clientName.length) isValidName = name.toLocaleLowerCase().includes(filters.clientName.toLocaleLowerCase())
      if (typeof filters.contractSignDate === 'string') isValidContractSignDate = contractSignDate === filters.contractSignDate

      return isValidContractId && isValidContractType && isValidContractSignDate && isValidBanker && isValidName && isValidEconomicGroup
    })
  }

  return {
    createContract,
    getContracts,
    getContractsDividedByStatus,
    handleValidateContract,
    updateContractValues,
    getContractByFilters
  }
}
