export const OBLIGATIONS_TO_DO = [
  'Send audited balance sheets by the end of the reporting period',
  'Send consolidated balance sheets',
  'Send covenant fulfillment certificates (in original format)',
  'Mantain active guarantees',
  'Inform about important changes in management',
  'Send annual report',
  'Mantain company property',
  'Mantain company control',
  'Mantain legal existence of the company',
  'Certificate of compliance',
  'Others'
]

export const OBLIGATIONS_NOT_TO_DO = [
  'Capital reduction',
  'Division or merger',
  'Assets divestiture',
  'Execute relevant modification of the bylaws',
  'Provide real guarantees',
  'Use equity as collateral for new financing',
  'Others'
]
