import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useContract } from '../../hooks/useContract'
import useDebounce from '../../hooks/useDebounce'
import { type Contract } from '../../types/contract'
import MyIconButton from '../MyIconButton/MyIconButton'
import { MyTable, type MyTableHeadersProps } from '../table/MyTable'
import { formatNumber, type ICURRENCY } from '../utils/currency'
import { INITIAL_FILTER_VALUES, type IFilters } from './SearchEngine'

interface Props {
  filters: IFilters
}

interface RowProps extends Contract {
  detailsButton: JSX.Element
  clientName: string
  totalAmountParsed: string
  contractSignDateParsed: string
  banker: string
  economicGroupParsed: string
}

export const SearchEngineTable = ({ filters }: Props) => {
  const debouncedFilters = useDebounce<IFilters>(filters)
  const [rows, setRows] = useState<RowProps[]>([])
  const { getContractByFilters } = useContract()
  const navigate = useNavigate()

  useEffect(() => {
    async function init () {
      if (JSON.stringify(filters) === JSON.stringify(INITIAL_FILTER_VALUES)) {
        setRows([])
      } else {
        const contracts = await getContractByFilters(filters)
        setRows(contracts.map(contract => ({
          ...contract,
          clientName: contract.client.name,
          totalAmountParsed: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true),
          banker: contract.client.banker,
          contractSignDateParsed: format(new Date(contract.contractSignDate), 'dd/MM/yyyy'),
          economicGroupParsed: contract.client.economicGroup,
          detailsButton:
            <MyIconButton
              aria-label='View details'
              boxSize={6}
              icon={MdAdd}
              colorScheme='teal'
              variant='outline'
              onClick={() => navigate(`/auth/search/${contract.contractId}`, { state: { contract } })}
            />
        })))
      }
    }

    void init()
    return () => { setRows([]) }
  }, [JSON.stringify(debouncedFilters)])

  return (
    <MyTable headers={headers} rows={rows}/>
  )
}

const headers: MyTableHeadersProps[] = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Borrower', value: 'clientName' },
  { label: 'Amount', value: 'totalAmountParsed' },
  { label: 'Currency', value: 'currency' },
  { label: 'Date', value: 'contractSignDateParsed' },
  { label: 'Economic Group', value: 'economicGroupParsed' },
  { label: 'Banker', value: 'banker' },
  { label: 'VIEW DETAILS', value: 'detailsButton', thAlign: 'center' }
]
