import { useState, useEffect } from 'react'
import { MdMonetizationOn } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import MyIconButton from '../../../../components/MyIconButton/MyIconButton'
import { MyTable, type MyTableHeadersProps } from '../../../../components/table/MyTable'
import { formatNumber, type ICURRENCY } from '../../../../components/utils/currency'
import { type Contract } from '../../../../types/contract'

interface RowsProps extends Contract {
  totalAmountParsed: string
  payButton: JSX.Element
}

interface Props {
  contracts: Contract[]
}

export const ContractsToPay = ({ contracts }: Props) => {
  const [rows, setRows] = useState<RowsProps[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    const rows = contracts.map((contract) => ({
      ...contract,
      totalAmountParsed: formatNumber(contract.totalAmount, contract.currency as ICURRENCY, true),
      payButton:
        <MyIconButton
          aria-label='pay'
          colorScheme='teal'
          icon={MdMonetizationOn}
          onClick={() => navigate('/auth/payments/pay', { state: { contract } })}
        />
    }))
    setRows(rows)
  }, [contracts])

  return (
    <MyTable headers={headers} rows={rows}/>
  )
}

const headers: MyTableHeadersProps[] = [
  { label: 'Contract id', value: 'contractId' },
  { label: 'Total amount', value: 'totalAmountParsed' },
  { label: 'Currency', value: 'currency' },
  { label: 'Add new payment', value: 'payButton', thAlign: 'center' }
]
