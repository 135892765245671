import { DeleteIcon, Icon } from '@chakra-ui/icons'
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Button, IconButton, Stack, Text } from '@chakra-ui/react'
import { useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { scrollToTop } from '../../../../../../components/utils/utils'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { useChakraToast } from '../../../../../../hooks/useChakraToast'
import { useDialog } from '../../../../../../hooks/useDialog'
import { type ICovenant } from '../../../../../../types/contract'
import { CovenantModal } from './CovenantModal'
import { v4 as uuidv4 } from 'uuid'
import { CovenantItem } from './CovenantItem'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'

export const Covenants = () => {
  const { contract, setContract, nextStep, prevStep } = useCreateContractContext()
  const [covenantsList, setCovenantsList] = useState<ICovenant[]>(contract.covenantsList)
  const { closeDialog, openDialog, isOpen } = useDialog()
  const { showToast } = useChakraToast()

  const onSubmit = () => {
    if (covenantsList.length === 0) {
      showToast('error', 'Please add at least one covenant')
      return
    }
    setContract(prev => ({ ...prev, covenantsList }))
    nextStep()
    scrollToTop()
  }

  const onAdd = (covenant: ICovenant) => {
    covenant.id = uuidv4()
    setCovenantsList(prev => [...prev, covenant])
    closeDialog()
  }

  const onDelete = (index: number) => {
    setCovenantsList(prev => prev.filter((_, i) => i !== index))
  }

  return (
    <Stack spacing={8}>
    <CovenantModal
      isOpen={isOpen}
      onClose={closeDialog}
      onAdd={onAdd}
    />
    <MainTitle title='9. Covenants' variant='subsection' styles={{ maxWidth: '100%' }} />
    { covenantsList.length === 0
      ? <Text fontSize={14}>Please add Covenants Information</Text>
      : <Accordion allowMultiple>
        {covenantsList.map((covenant, index) =>
            <AccordionItem key={index}>
              <Box display='flex' justifyContent='space-between' gap={2}>
                <AccordionButton width='100%'>
                  <Box as="span" flex='1' textAlign='left'>
                    <h2> Covenant #{index + 1} </h2>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
                <IconButton
                  backgroundColor='transparent'
                  aria-label='delete'
                  color='#505050'
                  onClick={() => onDelete(index)}
                  icon={<Icon as={DeleteIcon} boxSize={5} />}
                />
              </Box>
              <AccordionPanel pb={4}>
                <CovenantItem covenant={covenant} />
              </AccordionPanel>
            </AccordionItem>
        )}
      </Accordion>
    }
      <Button
        rightIcon={<Icon as={MdAdd} boxSize={4} />}
        maxWidth='fit-content'
        fontSize={12}
        onClick={openDialog}
        py='8px'
        px='24px'
      >
        Add Covenant
      </Button>
      <Box width='100%' textAlign='right' mt='8px'>
        <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
          Previous Step
        </Button>
        <Button ml='12px' colorScheme='teal' onClick={onSubmit} fontSize={{ base: 14, lg: 16 }}>
          Next step
        </Button>
      </Box>
    </Stack>
  )
}
