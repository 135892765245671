import { useContext } from 'react'
import {
  ProSidebar,
  Menu,
  SidebarHeader,
  SidebarContent,
  SidebarFooter
} from 'react-pro-sidebar'

import 'react-pro-sidebar/dist/css/styles.css'

import { Divider, Image, Stack, Text } from '@chakra-ui/react'
import AuthContext from '../../store/auth-context'
import './Sidebar.css'
import { SidebarItem } from './SidebarItem'

export const DesktopSidebar = ({ collapsed, routes }) => {
  const authCtx = useContext(AuthContext)

  const role = authCtx.user.role

  return (
    <ProSidebar collapsed={collapsed}>
      <SidebarHeader>
        <Image
          src='https://firebasestorage.googleapis.com/v0/b/syndicated-loans-prototype.appspot.com/o/static%2Flogo.png?alt=media&token=6d8ca784-f084-462d-899c-867954ec4552'
          className={`sidebar-header__logo ${!collapsed ? 'opened' : ''}`}
        />
      </SidebarHeader>
      { !collapsed &&
        <Text textOverflow='ellipsis' whiteSpace='nowrap' align='center' mb={6}>
          Logged in as {role}
        </Text>
      }
      <SidebarContent>
        <Menu iconShape='square' >
          { routes.map(SidebarItem)}
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        {!collapsed && (
          <Stack spacing={4} p={4} display='flex' justifyContent='center' alignItems='center'>
            <Divider maxWidth='65%'/>
            <Stack spacing='0.5'>
              <Text
                fontSize={12}
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                textAlign={'center'}
              >
                © {new Date().getFullYear()} QuantumX
              </Text>
              <Text
                fontSize={12}
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                textAlign={'center'}
              >
                All rights reserved
              </Text>
            </Stack>
          </Stack>
        )}
      </SidebarFooter>
    </ProSidebar>
  )
}
