import { Form, Formik } from 'formik'
import { type ILog } from '../../../../types/log'
import * as yup from 'yup'
import { Box, Button, SimpleGrid } from '@chakra-ui/react'
import FormikControl from '../../../../components/formComponents/FormikControl'
import { type Contract } from '../../../../types/contract'

interface Props {
  onSubmit: (log: ILog) => void
  contracts: Contract[]
}

export const LogForm = ({ onSubmit, contracts }: Props) => {
  const contractOptions = contracts.map(({ contractId }) => {
    return { value: contractId, key: contractId }
  })

  return (
    <Formik
      initialValues={INITIAL_VALUES}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      { () =>
        <Form>
          <SimpleGrid columns={[1, null, 2]} spacing='16px'>
            <FormikControl
              control='chakraSelect'
              label='Banker / Executive'
              name='banker'
              options={[
                { value: 'Vivian Yung', key: 'Vivian Yung' },
                { value: 'John Doe', key: 'John Doe' },
                { value: 'Patrick Rowlins', key: 'Patrick Rowlins' }
              ]}
            />
            <FormikControl
              control='chakraSelect'
              label='Contract ID'
              name='contractId'
              options={contractOptions}
            />
            <FormikControl
              control='chakraDatePicker'
              label='Entry Date'
              name='entryDate'
            />
            <FormikControl
              control='chakraSelect'
              label='Event Type'
              name='event'
              options={[
                { value: 'Commercial Event', key: 'Commercial Event' },
                { value: 'Financial Event', key: 'Financial Event' },
                { value: 'Legal Event', key: 'Legal Event' },
                { value: 'Other', key: 'Other' }
              ]}
            />
          </SimpleGrid>
          <Box mt='12px'>
            <FormikControl
              control='chakraTextarea'
              label='Description'
              name='description'
              height='10px'
            />
          </Box>
          <Box mt='4rem' display='flex' justifyContent='center'>
            <Button colorScheme='teal' type='submit' fontSize={{ base: 14, lg: 16 }}>
              Save log
            </Button>
          </Box>
        </Form>
      }
    </Formik>
  )
}

const INITIAL_VALUES: ILog = {
  banker: '',
  entryDate: new Date(),
  description: '',
  event: '',
  contractId: ''
}

const validationSchema = yup.object({
  banker: yup.string().required('Required'),
  entryDate: yup.date().typeError('Must be a valid format date').required('Required'),
  description: yup.string().required('Required'),
  event: yup.string().required('Required'),
  contractId: yup.string().required('Required')
})
