import { Box, Button, SimpleGrid, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import FormikControl from '../../../../../../components/formComponents/FormikControl'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { scrollToTop } from '../../../../../../components/utils/utils'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { type Contract } from '../../../../../../types/contract'
import * as yup from 'yup'
import { CURRENCY_OPTIONS } from '../../../../../../components/utils/currency'

export const Fees = () => {
  const { contract, setContract, nextStep, prevStep } = useCreateContractContext()

  const onSubmit = (values: Contract) => {
    setContract(prev => { return { ...prev, ...values } })
    nextStep()
    scrollToTop()
  }

  return (
    <Stack spacing={8} mb={12}>
      <MainTitle title='10. Fees' variant='subsection' styles={{ maxWidth: '100%' }} />
      <Formik
        initialValues={contract}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        { ({ values }) =>
          <Form>
            <SimpleGrid columns={[1, null, 2]} spacing='16px' mb='16px'>
              <FormikControl
                control='chakraSelect'
                name='fees.type'
                label='Fee Type'
                options={[
                  { key: 'Other', value: 'Other' }
                ]}
              />
              <FormikControl control='chakraDatePicker' name='fees.paymentDate' label='Payment Date' />
              <FormikControl
                control='chakraSelect'
                name='fees.currency'
                label='Currency'
                options={CURRENCY_OPTIONS}
              />
              <FormikControl
                control='chakraCurrencyInput'
                currency={values.fees.currency}
                name='fees.amount'
                label='Fee Amount'
              />
              <FormikControl
                control='chakraNumberInput'
                currency={values.fees.currency}
                name='fees.quantity'
                label='Payment Quantity'
              />
              <FormikControl
                control='chakraSelect'
                name='fees.period'
                label='Fee Period'
                options={[
                  { key: 'Other', value: 'Other' }
                ]}
              />
            </SimpleGrid>
            <FormikControl control='chakraTextarea' name='fees.note' label='Notes' height='20' placeholder='Write here'/>
            <Box width='100%' textAlign='right' mt='8px'>
              <Button colorScheme='blackAlpha' onClick={prevStep} fontSize={{ base: 14, lg: 16 }}>
                Previous Step
              </Button>
              <Button type='submit' colorScheme='teal' ml='12px' fontSize={{ base: 14, lg: 16 }}>
                Next step
              </Button>
            </Box>
          </Form>
        }
      </Formik>
    </Stack>
  )
}

const validationSchema = yup.object({
  fees: yup.object({
    type: yup.string().required('Required'),
    paymentDate: yup.date().typeError('Invalid Format Date').required('Required'),
    currency: yup.string().required('Required'),
    amount: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
    quantity: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
    period: yup.string().required('Required'),
    note: yup.string()
  })
})
