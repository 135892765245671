import { Box, Text } from '@chakra-ui/react'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { type IPaymentStructure } from '../../../../../../types/paymentStructure'

interface Props {
  paymentStructure: IPaymentStructure
}

export const PaymentStructureItem = ({ paymentStructure }: Props) => {
  const { contract } = useCreateContractContext()

  const BOX_ONE = [
    { label: 'Tranche', value: contract.financingStructures.find(tranche => tranche.id === paymentStructure.tranche)?.tranche },
    { label: 'PAYMENT TYPE', value: paymentStructure.paymentType },
    { label: 'BANK', value: contract.banks.find(bank => bank.id === paymentStructure.bank)?.bankName }
  ]
  const BOX_TWO = [
    { label: 'CURRENCY', value: paymentStructure.currency },
    { label: '# OF INSTALLMENTS', value: paymentStructure.installments },
    { label: 'TRANCHE VALUE', value: paymentStructure.trancheValue }
  ]
  return (
    <Box display='flex' gap='20px'>
      <Box
        backgroundColor='gray.200'
        p='16px'
        borderRadius={12}
        width='45%'
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_ONE.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px'>
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='55%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
      <Box
        backgroundColor='gray.200'
        p='16px'
        width='45%'
        height='fit-content'
        borderRadius={12}
        boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
      >
        { BOX_TWO.map((item, index) =>
          <Box key={index} display='flex' justifyContent='space-between' pb='8px'>
            <Text fontWeight={700} fontSize={14} textTransform='uppercase' color='#2D3748'>
              {item.label}
            </Text>
            <Text textAlign='left' fontSize={14} width='40%'>
              {item.value}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  )
}
