import { useState } from 'react'

interface DialogState<T> {
  isOpen: boolean
  data: T
}

const initialState: DialogState<any> = {
  isOpen: false,
  data: null
}

export interface DialogProps {
  isOpen: boolean
  data?: any
  onClose: () => void
}

export const useDialog = <T>() => {
  const [{ isOpen, data }, setDialogState] = useState<DialogState<T>>(initialState)

  const openDialog = (data: any = null) => {
    setDialogState({ isOpen: true, data })
  }

  const closeDialog = () => {
    setDialogState(initialState)
  }

  return {
    openDialog,
    closeDialog,
    isOpen,
    data
  }
}
