import { AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, SimpleGrid, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import { MainTitle } from '../../../../../components/mainTitle/MainTitle'
import { formatNumber, type ICURRENCY } from '../../../../../components/utils/currency'
import { type IFees } from '../../../../../types/contract'
import { Item } from './Item'

export const Fees = ({ fees }: { fees: IFees }) => {
  const FEES = [
    { left: 'FEE TYPE', right: fees.type },
    { left: 'PAYMENT DATE', right: format(new Date(fees.paymentDate), 'dd/MM/yyyy') },
    { left: 'CURRENCY', right: fees.currency },
    { left: 'FEE AMOUNT', right: formatNumber(fees.amount, fees.currency as ICURRENCY) },
    { left: 'PAYMENT QUANTITY', right: formatNumber(fees.quantity, fees.currency as ICURRENCY) },
    { left: 'FEE PERIOD', right: fees.period }
  ]

  return (
    <AccordionItem>
      <h2>
        <AccordionButton>
          <Box as="span" flex='1' textAlign='left'>
            Fees
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <SimpleGrid minChildWidth='300px' spacing={5}>
          <Item info={FEES} title='10. Fees'/>
          <Box
            bg='#E2E8F0'
            borderRadius={12}
            minW='300px'
            p='26px'
            h='fit-content'
            boxShadow='1px 2px 3px 0px rgba(0, 0, 0, 0.25)'
          >
            <MainTitle title='10. Notes' variant='subsection' styles={{ minWidth: '100%', fontSize: 18, marginBottom: 16 }} />
            <Text>{fees.note}</Text>
          </Box>
        </SimpleGrid>
      </AccordionPanel>
    </AccordionItem>
  )
}
