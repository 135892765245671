import { BrowserRouter } from 'react-router-dom'
import AppRoutes from './routes'
import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import Loader from './components/loader/Loader'
import './components/loader/Loader.css'
import { createTheme, ThemeProvider } from '@mui/material'
import { AuthContextProvider } from './store/auth-context'

const theme = extendTheme({
  fonts: {
    body: 'Inter',
    heading: 'Inter',
    mono: 'Inter'
  },
  colors: {
    blackAlpha: {
      300: '#F7F7F733',
      600: '#787878',
      700: '#383838',
      800: '#1D1D1D'
    },
    gray: {
      700: '#3D4D6A'
    },
    teal: {
      50: '#2AADF8',
      100: '#269CE0',
      500: '#38B6FF',
      600: '#319EDE',
      700: '#1A5678'
    }
  },
  components: {
    Button: {
      variants: {
        outline: {
          _hover: {
            color: 'white'
          }
        }
      }
    }
  }
})

const MUItheme = createTheme({
  typography: {
    fontFamily: [
      'Inter'
    ].join(',')
  }
})

const App = () => (
  <BrowserRouter>
    <ThemeProvider theme={MUItheme}>
      <ChakraProvider theme={theme}>
        <AuthContextProvider>
          <Loader />
          <AppRoutes />
        </AuthContextProvider>
      </ChakraProvider>
    </ThemeProvider>
  </BrowserRouter>
)

export default App
