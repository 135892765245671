import { Box, Button, SimpleGrid, Stack } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import FormikControl, { type FormikControlProps } from '../../../../../../components/formComponents/FormikControl'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { CURRENCY_OPTIONS } from '../../../../../../components/utils/currency'
import { useCreateContractContext } from '../../../../../../contexts/CreateContractContext'
import { Lawyers } from './Lawyers'
import * as yup from 'yup'
import { type Contract } from '../../../../../../types/contract'
import { scrollToTop } from '../../../../../../components/utils/utils'

export const BasicInfo = () => {
  const { contract, setContract, nextStep } = useCreateContractContext()

  const onSubmit = (values: Contract) => {
    setContract(prev => { return { ...prev, ...values } })
    nextStep()
    scrollToTop()
  }

  return (
    <Stack spacing={8} mb={12}>
      <MainTitle title='1. Client' variant='subsection' styles={{ maxWidth: '100%' }} />
      <Formik
        initialValues={contract}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        { ({ values }) =>
          <Form>
            <SimpleGrid columns={[1, null, 2]} spacing='16px'>
              { INPUTS.map((input) => <FormikControl key={input.name} {...input as FormikControlProps} />)}
            </SimpleGrid>
            <Stack spacing={8} mb={12} mt={16}>
              <MainTitle title='2. Contract' variant='subsection' styles={{ maxWidth: '100%' }} />
              <SimpleGrid columns={[1, null, 2]} spacing='16px'>
                <FormikControl control='chakraInput' name='contractId' label='Contract ID' />
                <FormikControl control='chakraDatePicker' name='contractSignDate' label='Contract Sign Date' />
                <FormikControl
                  control='chakraSelect'
                  name='contractType'
                  label='Contract Type'
                  options={CONTRACT_TYPE_OPTIONS}
                />
                <FormikControl control='chakraDatePicker' name='contractExpirationDate' label='Contract Expiration Date' />
                <FormikControl
                  control='chakraSelect'
                  name='currency'
                  label='Currency'
                  options={CURRENCY_OPTIONS}
                />
                <FormikControl
                  control='chakraSelect'
                  name='contractLaw'
                  label='Contract Law'
                  options={CONTRACT_LAW_OPTIONS}
                />
                <FormikControl
                  control='chakraCurrencyInput'
                  currency={values.currency}
                  name='totalAmount'
                  placeholder='Please enter an amount'
                  label='Total Amount'
                />
                <FormikControl
                  control='chakraSelect'
                  name='guarantees'
                  label='Guarantees'
                  options={GUARANTEES_OPTIONS}
                />
                <FormikControl
                  control='chakraNumberInput'
                  currency={values.currency}
                  name='percentageBank'
                  placeholder='Please enter a percentage'
                  label='Percentage Bank'
                  suffix='%'
                />
                <FormikControl
                  control='chakraSelect'
                  name='covenants'
                  label='Covenants'
                  options={COVENANTS_OPTIONS}
                />
                <FormikControl control='chakraInput' name='term' label='Term' />
                <FormikControl control='chakraInput' name='financingPurpose' label='Financing Purpose' />
              </SimpleGrid>
            </Stack>
            <Lawyers />
            <Box width='100%' textAlign='right'>
              <Button type='submit' colorScheme='teal'>
                Next step
              </Button>
            </Box>
          </Form>
        }
      </Formik>
    </Stack>
  )
}

const INPUTS = [
  {
    control: 'chakraInput',
    name: 'client.id',
    label: 'ID'
  },
  {
    control: 'chakraInput',
    name: 'client.economicGroup',
    label: 'Economic Group'
  },
  {
    control: 'chakraInput',
    name: 'client.name',
    label: 'Name'
  },
  {
    control: 'chakraInput',
    name: 'client.bankingBranch',
    label: 'Banking Branch'
  },
  {
    control: 'chakraSelect',
    name: 'client.seniorBanker',
    label: 'Senior Banker',
    options: [
      { value: 'Aldo Banometti', key: 'Aldo Banometti' },
      { value: 'Jane Doe', key: 'Jane Doe' },
      { value: 'Robert Qualls', key: 'Robert Qualls' }
    ]
  },
  {
    control: 'chakraSelect',
    name: 'client.banker',
    label: 'Banker / Executive',
    options: [
      { value: 'Vivian Yung', key: 'Vivian Yung' },
      { value: 'John Doe', key: 'John Doe' },
      { value: 'Patrick Rowlins', key: 'Patrick Rowlins' }
    ]
  }
]

const CONTRACT_TYPE_OPTIONS = [
  { key: 'Syndicated Loan', value: 'Syndicated Loan' },
  { key: 'Bilateral Loan', value: 'Bilateral Loan' },
  { key: 'Project Finance', value: 'Project Finance' }
]

const CONTRACT_LAW_OPTIONS = [
  { key: 'American', value: 'American' },
  { key: 'Chilean', value: 'Chilean' }
]

const GUARANTEES_OPTIONS = [
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' }
]

const COVENANTS_OPTIONS = [
  { key: 'Yes', value: 'Yes' },
  { key: 'No', value: 'No' }
]

const clientValidationSchema = yup.object({
  id: yup.string().required('Required'),
  name: yup.string().required('Required'),
  economicGroup: yup.string().required('Required'),
  bankingBranch: yup.string().required('Required'),
  seniorBanker: yup.string().required('Required'),
  banker: yup.string().required('Required')
})

const validationSchema = yup.object({
  client: clientValidationSchema.required(),
  contractId: yup.string().required('Required'),
  contractSignDate: yup.date().typeError('Invalid date format').max(yup.ref('contractExpirationDate'), 'Contract Sign Date must be before of Contract Expiration Date').required('Required'),
  contractExpirationDate: yup.date().typeError('Invalid date format').required('Required'),
  contractType: yup.string().required('Required'),
  currency: yup.string().required('Required'),
  contractLaw: yup.string().required('Required'),
  totalAmount: yup.number().min(0.001, 'Must be greater than 0').required('Required'),
  guarantees: yup.string().required('Required'),
  percentageBank: yup.number().max(100, 'Must be less than 100%').required('Required'),
  covenants: yup.string().required('Required'),
  financingPurpose: yup.string().required('Required'),
  term: yup.string().required('Required'),
  lawyers: yup.object({
    bankResponsible: yup.object({
      name: yup.string().required('Required')
    }),
    externalLawyer: yup.object({
      name: yup.string().required('Required'),
      phone: yup.string().matches(/^[0-9\s]+$/, 'Invalid phone format').required('Required'),
      email: yup.string().email('Invalid email format').required('Required'),
      office: yup.string().required('Required'),
      notary: yup.string().required('Required'),
      repertoire: yup.string().required('Required')
    })
  })
})
