import { Table, TableContainer, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { usePagination } from '../../hooks/usePagination'
import { type MyTableProps } from './MyTable'

export const DesktopTable = ({ headers, rows = [] }: MyTableProps) => {
  const { MyPagination, filterData } = usePagination(rows)
  return (
    <>
      <TableContainer borderColor='gray.200' borderWidth={1} borderRadius={12}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              { headers.map((header, index) =>
                <Th
                  key={index}
                  fontSize={12}
                  isNumeric={Boolean(header.isNumeric)}
                  textAlign={header?.thAlign ? header.thAlign : 'left' }
                >{ header.label }</Th>
              )}
            </Tr>
          </Thead>
          <Tbody>
            { filterData.map((row, index) =>
              <Tr key={index}>
                { headers.map((header, index) =>
                  <Td
                    key={index}
                    isNumeric={Boolean(header.isNumeric)}
                    fontSize={14}
                  >{ setLabel(row[header.value]) }</Td>
                )}
              </Tr>
            )}
          </Tbody>
        </Table>
      </TableContainer>
      <MyPagination />
    </>
  )
}

function setLabel (obj: any) {
  if (typeof obj === 'object' && obj?.join) {
    return obj.join(', ')
  }
  return obj
}
