import PropTypes from 'prop-types'
import ChakraInput, { type ChakraInputProps } from './chakraComponents/ChakraInput'
import ChakraTextarea, { type ChakraTextareaProps } from './chakraComponents/ChakraTextarea'
import ChakraSelect, { type ChakraSelectProps } from './chakraComponents/ChakraSelect'
import { ChakraNumberInput, type ChakraNumberyInputProps } from './chakraComponents/ChakraNumberInput'
import { ChakraCurrencyInput, type ChakraCurrencyInputProps } from './chakraComponents/ChakraCurrencyInput'
import ChakraPasswordInput, { type ChakraPasswordInputProps } from './chakraComponents/ChakraPasswordInput'
import { ChakraDatePicker, type ChakraDatePickerProps } from './chakraComponents/ChakraDatePicker'
import ChakraRadio, { type ChakraRadioProps } from './chakraComponents/ChakraRadio'

type ChakraInputType =
  'chakraInput' |
  'chakraTextarea' |
  'chakraSelect' |
  'chakraNumberInput' |
  'chakraCurrencyInput' |
  'chakraPasswordInput' |
  'chakraDatePicker' |
  'chakraRadio'

export interface FormikControlProps {
  control: ChakraInputType
  name: string
  label: string
  [propName: string]: any
}

const FormikControl = (props: FormikControlProps) => {
  const { control, ...rest } = props

  switch (control) {
    case 'chakraInput':
      return <ChakraInput {...rest as ChakraInputProps} />
    case 'chakraPasswordInput':
      return <ChakraPasswordInput {...rest as ChakraPasswordInputProps } />
    case 'chakraTextarea':
      return <ChakraTextarea {...rest as ChakraTextareaProps} />
    case 'chakraSelect':
      return <ChakraSelect {...rest as ChakraSelectProps} />
    case 'chakraNumberInput':
      return <ChakraNumberInput {...rest as ChakraNumberyInputProps} />
    case 'chakraCurrencyInput':
      return <ChakraCurrencyInput {...rest as ChakraCurrencyInputProps} />
    case 'chakraDatePicker':
      return <ChakraDatePicker {...rest as ChakraDatePickerProps} />
    case 'chakraRadio':
      return <ChakraRadio {...rest as ChakraRadioProps} />
    default:
      return null
  }
}

FormikControl.propTypes = {
  control: PropTypes.string.isRequired
}

export default FormikControl
