import {
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import { format } from 'date-fns'
import { formatNumber, type ICURRENCY } from '../utils/currency'
import { usePagination } from '../../hooks/usePagination'
import { AmortizationRowStatus } from './AmortizationRowStatus'
import { type IPayment } from '../../types/payment'
import { useResponsive } from '../../hooks/useResponsive'
import { type IFinancingStructure } from '../../types/contract'
import { localeDate } from '../utils/utils'

interface Props {
  tranche: IFinancingStructure
  payments: IPayment[]
  currency: ICURRENCY
}

interface AmortizationData {
  paymentDate: Date
  startingBalance: number
  endingBalance: number
  payment: number
  principal: number
  index: number
}

const StaticAmortization = ({ tranche, currency, payments }: Props) => {
  const [data, setData] = useState<AmortizationData[]>([])

  useEffect(() => {
    updateAmortization()
  }, [])

  const updateAmortization = () => {
    const amortization: AmortizationData[] = []
    if (!tranche.amortizationTable) return
    for (let i = 0; i < tranche.amortizationTable?.length; i++) {
      console.log(tranche.amortizationTable[i].endingBalance)
      amortization.push({
        paymentDate: new Date(tranche.amortizationTable[i].paymentDate),
        startingBalance: tranche.amortizationTable[i].beginningBalance,
        payment: tranche.amortizationTable[i].payment,
        principal: tranche.amortizationTable[i].principal,
        endingBalance: isNaN(tranche.amortizationTable[i].endingBalance) ? 0 : tranche.amortizationTable[i].endingBalance,
        index: i
      })
    }
    setData(amortization)
  }
  return (
    <TablePaginated rows={data} currency={currency} payments={payments} />
  )
}

interface TableProps {
  currency: ICURRENCY
  rows: AmortizationData[]
  payments: IPayment[]
}

const TablePaginated = ({ rows, currency, payments }: TableProps) => {
  const { MyPagination, filterData } = usePagination(rows)
  const { isMobile } = useResponsive()

  return (
    <Stack spacing={3}>
      <TableContainer style={{ marginTop: '16px' }}>
        <Table variant='striped' size={isMobile ? 'sm' : 'md'} >
          <Thead>
            <Tr>
              <Th></Th>
              <Th>Payment Date</Th>
              <Th isNumeric>Beginning Balance</Th>
              <Th isNumeric>Payment</Th>
              <Th isNumeric>Principal</Th>
              <Th isNumeric>Ending Balance</Th>
              <Th>Status</Th>
            </Tr>
          </Thead>
          <Tbody>
            {filterData.map((row: AmortizationData, index: number) => (
              <Tr key={index} fontSize={14} >
                <Td>{row.index + 1}</Td>
                <Td>{format(localeDate(row.paymentDate), 'dd/MM/yyyy')}</Td>
                <Td isNumeric>{formatNumber(row.startingBalance.toFixed(2), currency, true)}</Td>
                <Td isNumeric>{formatNumber(Math.abs(row.payment).toFixed(2), currency, true)}</Td >
                <Td isNumeric>{formatNumber(Math.abs(row.principal).toFixed(2), currency, true)}</Td>
                <Td isNumeric>{formatNumber(row.endingBalance.toFixed(2), currency, true)}</Td>
                <Td>
                  <AmortizationRowStatus
                    date={new Date(row.paymentDate)}
                    havePaid={Boolean(payments?.[row.index])}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <MyPagination />
    </Stack>
  )
}

export default StaticAmortization
