import { Checkbox, SimpleGrid, Stack } from '@chakra-ui/react'
import type React from 'react'
import { Fragment } from 'react'
import { MainTitle } from '../../../../../../components/mainTitle/MainTitle'
import { type IObligation } from '../../../../../../types/contract'

interface Props {
  title: string
  obligationsList: string[]
  obligationsChecked: IObligation[]
  setter: React.Dispatch<React.SetStateAction<IObligation[]>>
}

export const ObligationItems = ({ obligationsChecked, obligationsList, setter, title }: Props) => {
  return (
    <Stack spacing={8}>
      <MainTitle title={title} variant='subsection' styles={{ maxWidth: '100%', paddingLeft: 20 }} />
      <SimpleGrid columns={1} spacing='16px' mb='16px'>
        {obligationsList.map((obligation, index) =>
          <Fragment key={index}>
            <Checkbox
              size='sm'
              isChecked={obligationsChecked.some(obligationChecked => obligationChecked.index === index)}
              onChange={e => {
                if (e.target.checked) {
                  setter(prev => [...prev, { index, label: obligation }])
                } else {
                  setter(prev => prev.filter(obligationChecked => obligationChecked.index !== index))
                }
              }}
            >{obligation}</Checkbox>
          </Fragment>
        )}
      </SimpleGrid>
    </Stack>
  )
}
