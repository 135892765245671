import { Stack } from '@chakra-ui/react'
import { MainTitle } from '../../../../components/mainTitle/MainTitle'
import { SearchEngine } from '../../../../components/searchEngine/SearchEngine'

export const SearchEnginAdmin = () => {
  return (
    <Stack spacing={16}>
      <MainTitle title='Search Engine' />
      <SearchEngine />
    </Stack>
  )
}
