import { FormControl, FormErrorMessage, FormLabel, Input } from '@chakra-ui/react'
import { NumericFormat } from 'react-number-format'
import { Field, getIn, type FieldProps } from 'formik'
import { currencies, type ICURRENCY } from '../../utils/currency'

export interface ChakraNumberyInputProps {
  name: string
  label: string
  currency: ICURRENCY
  [propName: string]: any
}

export const ChakraNumberInput = ({ name, label, currency, ...rest }: ChakraNumberyInputProps) => {
  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => (
        <FormControl isInvalid={Boolean(getIn(form.errors, name) && getIn(form.touched, name))}>
          <FormLabel fontSize={{ base: 14, lg: 16 }} htmlFor={name} color='gray.700' fontWeight={600}>{label}</FormLabel>
            <NumericFormat
              {...currencies[currency]}
              {...rest}
              value={field.value}
              onValueChange={(values) => {
                const { floatValue } = values
                form.setFieldValue(name, floatValue)
              }}
              customInput={Input}
            />
          <FormErrorMessage textTransform='capitalize'>{getIn(form.errors, name)}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  )
}
