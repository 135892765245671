import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text } from '@chakra-ui/react'
import { type ILog } from '../../../../types/log'

interface Props {
  isOpen: boolean
  onClose: () => void
  log: ILog | undefined
}

export const LogDetails = ({ isOpen, log, onClose }: Props) => {
  if (!log) return null

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      motionPreset='slideInBottom'
    >
      <ModalOverlay bg='blackAlpha.500' backdropFilter='blur(4px)' />
      <ModalContent p='1.5rem' m='2rem' maxWidth='360px'>
        <ModalCloseButton />
        <ModalHeader ml='8px'fontWeight={700} fontSize={18} color='#2D3748'>
          Contract No.{log.contractId}
        </ModalHeader>
        <ModalBody borderTop='1px #CBD5E0 solid' borderBottom='1px #CBD5E0 solid' >
          <Text mb='18px' fontSize={{ base: 14, sm: 14, md: 14, lg: 16 }}>Description</Text>
          <Text fontSize={{ base: 14, sm: 14, md: 14, lg: 16 }}>{log.description}</Text>
        </ModalBody>
        <ModalFooter display='flex' justifyContent='end'>
          <Button colorScheme='teal' onClick={onClose} fontSize={{ base: 14, lg: 16 }}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
      </Modal>
  )
}
