import { Box, Button, Stack, Text } from '@chakra-ui/react'
import { LoginLayout } from '../../layout/login/LoginLayout'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import FormikControl from '../../components/formComponents/FormikControl'
import { useAuthContext } from '../../store/auth-context'

const INITIAL_VALUES = {
  email: ''
}

export const RecoverPassword = () => {
  const { handlePasswordRecovery } = useAuthContext()
  const navigate = useNavigate()

  const onSubmit = async (values: typeof INITIAL_VALUES) => {
    const resp = await handlePasswordRecovery(values.email)
    if (resp) navigate('/', { replace: true })
  }

  return (
    <LoginLayout>
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {() =>
          <Form style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Stack w='md' spacing='8' p={4}>
              <Text fontSize={24} color='gray.700' fontWeight={500} textAlign='center'>Password recovery</Text>
              <Text fontSize={16} color='gray.700' fontWeight={400} textAlign='center'>Don&apos;t worry, we will help you recover your password, enter your email address below.</Text>
              <FormikControl
                control='chakraInput'
                name='email'
                label='Email'
                placeholder='Enter an email'
              />
              <Box minWidth='100%'>
                <Button
                  minWidth='100%'
                  colorScheme='teal'
                  fontSize={{ base: 14, lg: 16 }}
                  type='submit'
                >
                  Send
                </Button>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Text fontSize='sm' color='gray.500' maxWidth='fit-content'>
                    Did you remember your password?&nbsp;
                  </Text>
                  <Text
                    color='#38B6FF'
                    fontSize='sm'
                    maxWidth='fit-content'
                    onClick={() => navigate('/', { replace: true })}
                    _hover={{ cursor: 'pointer', textDecoration: 'underline' }}
                  >
                    Click here
                  </Text>
                </Box>
              </Box>
            </Stack>
          </Form>
        }
      </Formik>
    </LoginLayout>
  )
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email format').required('Required')
})
